/** Тип группы полей для типа продажи. */
export enum SaleTypeFieldGroupType {
    /** Описание. */
    Info = "info",

    /** Клиент. */
    Client = "client",

    /** Оплата. */
    Payment = "payment",
}
