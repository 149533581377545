import { IWorkUseCase, IWork, IReadQuery, ISelectQuery, IWorkImportDto, ISelectedData } from "@lib";
import { IWorkCreateDto, IWorkUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class WorkUseCase implements IWorkUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<IWork>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IWork>>(`/companies/${companyId}/lists/works`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    // public async selectForOffice(companyId: string, officeId: string): Promise<IWork[]> {
    //     try {
    //         const response = await Api.get<IWork[]>(`/companies/${companyId}/offices/${officeId}/works`);
    //         return response.data;
    //     } catch (e: any) {
    //         throw new DataLoadException(e);
    //     }
    // }

    public async get(companyId: string, id: string): Promise<IWork> {
        try {
            const response = await Api.get<IWork>(`/companies/${companyId}/lists/works/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IWorkCreateDto): Promise<IWork> {
        try {
            const response = await Api.post<IWork>(`/companies/${companyId}/lists/works`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: IWorkCreateDto[]): Promise<IWork[]> {
        try {
            const response = await Api.post<IWork[]>(`/companies/${companyId}/lists/works/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IWorkUpdateDto): Promise<IWork> {
        try {
            const response = await Api.put<IWork>(`/companies/${companyId}/lists/works/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/works/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async import(companyId: string, file: File, dto: IWorkImportDto): Promise<IWork[]> {
        try {
            const data = new FormData();
            data.append("file", file);
            for (const key in dto) {
                data.append(key, (dto as any)[key]);
            }

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const response = await Api.post<IWork[]>(
                `/companies/${companyId}/lists/works/actions/import`,
                data,
                config,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async export(companyId: string, query?: IReadQuery): Promise<string> {
        try {
            // https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios

            const params = QueryConverter.objectToQuery(query);
            const response = await Api.post<string>(`/companies/${companyId}/lists/works/actions/export`, undefined, {
                params,
                responseType: "blob",
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
