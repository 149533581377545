import _Vue from "vue";

function installWidget(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const head = document.head || document.getElementsByTagName("head")[0];

        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`;
        head.appendChild(script);

        const body = document.body || document.getElementsByTagName("body")[0];

        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        body.appendChild(noscript);

        resolve(true);
    });
}

export function VueGoogleTagManagerWidget(Vue: typeof _Vue, options: { id: string }): void {
    if (document.readyState === "complete") {
        installWidget(options.id);
    } else {
        window.addEventListener("load", () => installWidget(options.id), false);
    }
}
