import { DataLoadException } from "@/core/exceptions";
import { ISupplier, ISupplierCreateDto, ISupplierUpdateDto, ISupplierUseCase } from "@lib";
import { Api } from "../axios";

export class SupplierUseCase implements ISupplierUseCase {
    public async select(companyId: string): Promise<ISupplier[]> {
        try {
            const response = await Api.get<ISupplier[]>(`/companies/${companyId}/lists/suppliers`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ISupplier> {
        try {
            const response = await Api.get<ISupplier>(`/companies/${companyId}/lists/suppliers`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ISupplierCreateDto): Promise<ISupplier> {
        try {
            const response = await Api.post<ISupplier>(`/companies/${companyId}/lists/suppliers`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: ISupplierUpdateDto): Promise<ISupplier> {
        try {
            const response = await Api.put<ISupplier>(`/companies/${companyId}/lists/suppliers/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/suppliers/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
