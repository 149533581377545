import { IModuleUseCase, IModule, IReadQuery, ISelectedData } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { QueryConverter } from "@/utils/plugins/query-converter";
import { Api } from "../axios";

export class ModuleUseCase implements IModuleUseCase {
    public async select(query?: IReadQuery): Promise<ISelectedData<IModule>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IModule>>("/modules", { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async getMany(ids: string[]): Promise<IModule[]> {
        // try {
        //     const params = QueryConverter.objectToQuery(query);
        //     const response = await Api.get<IModule[]>("/modules/many", { params });
        //     return response.data;
        // } catch (e: any) {
        //     throw new DataLoadException(e);
        // }

        throw new Error("Not implemented yet");
    }

    public async get(id: string): Promise<IModule> {
        try {
            const response = await Api.get<IModule>(`/modules/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
