import { ISalePaymentUseCase, ISale, ISalePaymentCreateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class SalePaymentUseCase implements ISalePaymentUseCase {
    public async create(companyId: string, shopId: string, saleId: string, dto: ISalePaymentCreateDto): Promise<ISale> {
        try {
            const response = await Api.post<ISale>(
                `/companies/${companyId}/shops/${shopId}/sales/${saleId}/payments`,
                dto,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, shopId: string, saleId: string, paymentId: string): Promise<ISale> {
        try {
            const response = await Api.delete<ISale>(
                `/companies/${companyId}/shops/${shopId}/sales/${saleId}/payments/${paymentId}`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
