import { Currency } from "@lib";
import { Gender } from "../../core/types";
import { ISelectOption } from "@core/types/common/select-options";
import { AppException } from "@/core/exceptions";

export interface ICurrencyMorphemes {
    main: string[];
    fraction: string[];
    gender: Gender;
}

export abstract class CurrencyUtils {
    public static getTypes(): Currency[] {
        return [
            Currency.RUB,
            Currency.BYN,
            Currency.UAH,
            Currency.KZT,
            Currency.GEL,
            Currency.AMD,
            Currency.AZN,
            Currency.KGS,
            Currency.TJS,
            Currency.TMT,
            Currency.UZS,
            Currency.MDL,
            Currency.USD,
            Currency.EUR,
        ];
    }

    public static getTypeText(type: Currency): string {
        switch (type) {
            case Currency.RUB:
                return "Рубль (РФ)";
            case Currency.BYN:
                return "Рубль (РБ)";
            case Currency.UAH:
                return "Гривна (Украина)";
            case Currency.KZT:
                return "Тенге (Казахстан)";
            case Currency.GEL:
                return "Лари (Грузия)";
            case Currency.AMD:
                return "Драм (Армения)";
            case Currency.AZN:
                return "Манат (Азербайджан)";
            case Currency.KGS:
                return "Сом (Киргизия)";
            case Currency.TJS:
                return "Сомони (Таджикистан)";
            case Currency.TMT:
                return "Манат (Туркменистан)";
            case Currency.UZS:
                return "Сум (Узбекистан)";
            case Currency.MDL:
                return "Лей (Молдавия)";
            case Currency.USD:
                return "Доллар (США)";
            case Currency.EUR:
                return "Евро (ЕС)";
        }
    }

    public static getOptions(): ISelectOption<Currency>[] {
        return CurrencyUtils.getTypes().map(t => ({
            id: t,
            name: CurrencyUtils.getTypeText(t),
        }));
    }

    public static getMorphemes(currency: string): ICurrencyMorphemes {
        switch (currency) {
            case Currency.RUB:
            case Currency.BYN:
                return {
                    main: ["рубль", "рубля", "рублей"],
                    fraction: ["копейка", "копейки", "копеек"],
                    gender: Gender.Male,
                };
            case Currency.UAH:
                return {
                    main: ["гривна", "гривны", "гривен"],
                    fraction: ["копейка", "копейки", "копеек"],
                    gender: Gender.Female,
                };
            case Currency.KZT:
                return {
                    main: ["тенге", "тенге", "тенге"],
                    fraction: ["тиын", "тиына", "тиынов"],
                    gender: Gender.Male,
                };
            case Currency.GEL:
                return {
                    main: ["лари", "лари", "лари"],
                    fraction: ["тетри", "тетри", "тетри"],
                    gender: Gender.Male,
                };
            case Currency.AMD:
                return {
                    main: ["драм", "драма", "драмов"],
                    fraction: ["лума", "лумы", "лум"],
                    gender: Gender.Male,
                };
            case Currency.AZN:
                return {
                    main: ["манат", "маната", "манатов"],
                    fraction: ["гяпик", "гяпика", "гяпиков"],
                    gender: Gender.Male,
                };
            case Currency.KGS:
                return {
                    main: ["сом", "сомa", "сомов"],
                    fraction: ["тыйын", "тыйына", "тыйынов"],
                    gender: Gender.Male,
                };
            case Currency.TJS:
                return {
                    main: ["сомони", "сомони", "сомони"],
                    fraction: ["дирам", "дирама", "дирамов"],
                    gender: Gender.Male,
                };
            case Currency.TMT:
                return {
                    main: ["манат", "маната", "манатов"],
                    fraction: ["тенге", "тенге", "тенге"],
                    gender: Gender.Male,
                };
            case Currency.UZS:
                return {
                    main: ["сум", "сума", "сумов"],
                    fraction: ["тийин", "тийина", "тийинов"],
                    gender: Gender.Male,
                };
            case Currency.MDL:
                return {
                    main: ["лей", "лея", "леев"],
                    fraction: ["бань", "баня", "баней"],
                    gender: Gender.Male,
                };
            case Currency.USD:
                return {
                    main: ["доллар", "доллара", "долларов"],
                    fraction: ["цент", "цента", "центов"],
                    gender: Gender.Male,
                };
            case Currency.EUR:
                return {
                    main: ["евро", "евро", "евро"],
                    fraction: ["цент", "цента", "центов"],
                    gender: Gender.Male,
                };
        }

        throw new AppException("Неизвестная валюта.");
    }
}
