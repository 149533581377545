import { IUserUseCase, IUser } from "@lib";
import { IUserUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class UserUseCase implements IUserUseCase {
    public async get(id: string): Promise<IUser> {
        try {
            const response = await Api.get<IUser>(`/users/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async isEmailTaken(email: string): Promise<boolean> {
        try {
            const params = { email };
            const response = await Api.get<boolean>("/users/email", { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(id: string, dto: IUserUpdateDto): Promise<IUser> {
        try {
            const response = await Api.put<IUser>(`/users/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changePassword(id: string, oldpassword: string, newpassword: string): Promise<void> {
        try {
            const dto = { oldpassword, newpassword };
            await Api.patch<void>(`/users/${id}/password`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async setPassword(id: string, password: string): Promise<void> {
        try {
            const dto = { password };
            await Api.patch<void>(`/users/${id}/set-password`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
