import {
    ITransactionUseCase,
    ITransaction,
    IReadQuery,
    ISelectedData,
    ITransactionDeleteManyDto,
    ITransactionCreateDto,
    ITransactionTransferDto,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class TransactionUseCase implements ITransactionUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<ITransaction>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<ITransaction>>(`/companies/${companyId}/transactions`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ITransaction> {
        try {
            const response = await Api.get<ITransaction>(`/companies/${companyId}/transactions/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ITransactionCreateDto): Promise<ITransaction> {
        try {
            const response = await Api.post<ITransaction>(`/companies/${companyId}/transactions`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async transfer(companyId: string, dto: ITransactionTransferDto): Promise<ITransaction[]> {
        try {
            const response = await Api.post<ITransaction[]>(
                `/companies/${companyId}/transactions/actions/transfer`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/transactions/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: ITransactionDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/transactions/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
