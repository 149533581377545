import {
    IGoodInventory,
    IGoodInventoryCreateDto,
    IGoodInventoryUpdateDto,
    IGoodInventoryUseCase,
    IReadQuery,
    ISelectedData,
} from "@lib";
import { Api } from "../axios";
import { DataLoadException } from "@/core/exceptions";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class GoodInventoryUseCase implements IGoodInventoryUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<IGoodInventory>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGoodInventory>>(`/companies/${companyId}/good-inventories`, {
                params,
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, inventoryId: string): Promise<IGoodInventory> {
        try {
            const response = await Api.get<IGoodInventory>(`/companies/${companyId}/good-inventories/${inventoryId}`);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IGoodInventoryCreateDto): Promise<IGoodInventory> {
        try {
            const response = await Api.post<IGoodInventory>(`/companies/${companyId}/good-inventories/`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async complete(companyId: string, inventoryId: string): Promise<IGoodInventory> {
        try {
            const response = await Api.post<IGoodInventory>(
                `/companies/${companyId}/good-inventories/${inventoryId}/complete`,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async resume(companyId: string, inventoryId: string): Promise<IGoodInventory> {
        try {
            const response = await Api.post<IGoodInventory>(
                `/companies/${companyId}/good-inventories/${inventoryId}/resume`,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, inventoryId: string, dto: IGoodInventoryUpdateDto): Promise<IGoodInventory> {
        try {
            const response = await Api.put<IGoodInventory>(
                `/companies/${companyId}/good-inventories/${inventoryId}`,
                dto,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, inventoryId: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/good-inventories/${inventoryId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
