import { Formatter } from "@/utils/formatter";

export function time(value: string, is24HrFormat: boolean = false): string {
    if (!value) {
        return "";
    }

    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    if (!is24HrFormat) {
        const time = hours > 12 ? "AM" : "PM";
        hours = hours % 12 || 12;
        return `${hours}:${min} ${time}`;
    }
    return `${hours}:${min}`;
}

export function date(value: any, fullDate: boolean = false): string {
    value = String(value);
    const date = value.slice(8, 10).trim();
    const month = value.slice(4, 7).trim();
    const year = value.slice(11, 15);

    if (!fullDate) {
        return `${date} ${month}`;
    } else {
        return `${date} ${month} ${year}`;
    }
}

export function month(value: any, showYear: boolean = true): string {
    value = String(value);
    const regx = /\w+\s(\w+)\s\d+\s(\d+)./;

    if (!showYear) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return regx.exec(value)![1];
    } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return regx.exec(value)![1] + " " + regx.exec(value)![2];
    }
}

export function datetime(value: string | Date, format: string = "LLL"): string {
    return Formatter.datetime(value, { format });
}

export function fromNow(value: string | Date): string {
    return Formatter.timeFromNow(value);
}
