import { IClientSourceUseCase, IClientSource } from "@lib";
import { IClientSourceCreateDto, IClientSourceUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class ClientSourceUseCase implements IClientSourceUseCase {
    public async select(companyId: string): Promise<IClientSource[]> {
        try {
            const response = await Api.get<IClientSource[]>(`/companies/${companyId}/lists/clientsources`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IClientSource> {
        try {
            const response = await Api.get<IClientSource>(`/companies/${companyId}/lists/clientsources/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IClientSourceCreateDto): Promise<IClientSource> {
        try {
            const response = await Api.post<IClientSource>(`/companies/${companyId}/lists/clientsources`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: IClientSourceCreateDto[]): Promise<IClientSource[]> {
        try {
            const response = await Api.post<IClientSource[]>(`/companies/${companyId}/lists/clientsources/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IClientSourceUpdateDto): Promise<IClientSource> {
        try {
            const response = await Api.put<IClientSource>(`/companies/${companyId}/lists/clientsources/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/clientsources/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
