import { CompanyType, CompanyPricingPlan, ICompanyFeatureOffices, ICompanyFeatureShops, IContacts, IOrder, ISale, ICompanyFeatureProjects } from "../../models";
import { CompanyFeature, ICompanyFeatureBarcodes, CompanyPaymentItem } from "../../models";
import { IEmployee, EmployeeSalaryType, IPermission } from "../../models";
import { ISalary, ISalarySubject } from "../../models";
import { IPermissionGrantDto } from "../permissions/dto";

export interface ICompanyCreateDto {
    type?: CompanyType;
    name: string;
    description?: string;
    email?: string;
    phone?: string;
    owner: string;
    ownerPosition: string;
    features?: {
        [CompanyFeature.Offices]?: ICompanyFeatureOffices;
        [CompanyFeature.Shops]?: ICompanyFeatureShops;
        [CompanyFeature.Barcodes]?: ICompanyFeatureBarcodes;
    },
    pricingPlan?: CompanyPricingPlan;
    meta?: any;
}

// TODO: уточнить тип meta

export interface ICompanyUpdateDto {
    name?: string;
    description?: string;
    // email?: string;
    // phone?: string;
    // street?: string;
    // city?: string;
    // region?: string;
    // country?: string;
    // zip?: string;
    // website?: string;
    contacts?: IContacts;
    features?: {
        [CompanyFeature.Offices]?: ICompanyFeatureOffices;
        [CompanyFeature.Shops]?: ICompanyFeatureShops;
        [CompanyFeature.Projects]?: ICompanyFeatureProjects;
        [CompanyFeature.Barcodes]?: ICompanyFeatureBarcodes;
    },
}

//

export enum CompanyFeatureBarcodesValidationError {
    InvalidSyntax = 1,
    AlreadyExists = 2,
}

export interface ICompanyFeatureBarcodesValidationResult {
    valid: boolean;
    error?: CompanyFeatureBarcodesValidationError;
}

//

export interface ICompanyPaymentCreateDto {
    item: CompanyPaymentItem;
    amount: number;
    comment?: string;
    sender?: string;
    meta?: any;
};

//

export interface IEmployeeGetResultDto {
    employee: IEmployee;
    permissions: IPermission | null;
}

export interface IEmployeeAuthCreateDto {
    name: string;
    officialName?: string;
    email: string;
    password: string;
    position: string;
    permissions: IPermissionGrantDto;
}

export interface IEmployeeAuthUpdateDto {
    name?: string;
    officialName?: string;
    email?: string;
    password?: string;
    position?: string;
    permissions?: IPermissionGrantDto;
}

//

export interface IEmployeeSettingsCreateDto {
    position: string;
    defaultAccount?: string;
}

export interface IEmployeeUserCreateDto {
    name: string;
    officialName?: string;
    phone?: string;
    email: string;
    password: string;
}

export interface IEmployeeCreateDto {
    user: IEmployeeUserCreateDto;
    settings: IEmployeeSettingsCreateDto;
    permissions: IPermissionGrantDto;
}

export interface IEmployeeUserUpdateDto {
    name?: string;
    officialName?: string;
    phone?: string;
    email?: string;
    password?: string;
}

export interface IEmployeeSettingsUpdateDto {
    position?: string;
    defaultAccount?: string;
    salary?: {
        work?: {
            type: EmployeeSalaryType;
            value: number;
        };
        material?: {
            type: EmployeeSalaryType;
            value: number;
        };
        sale?: {
            type: EmployeeSalaryType;
            value: number;
        };
    };
}

export interface IEmployeeUpdateDto {
    user?: IEmployeeUserUpdateDto;
    settings?: IEmployeeSettingsUpdateDto;
    permissions?: IPermissionGrantDto;
}

//

export interface IPartnerUpdateDto {
    guests: string[];
}

//

export interface ISalarySelectDto {
    timezone?: string;
    from?: string;
    to?: string;
    stages?: string[]; //("ready" | "closed")[];
}

export type OrderWithSalariesDto = IOrder & { salaries: ISalary[] };
export type SaleWithSalariesDto = ISale & { salaries: ISalary[] };

export interface ISalaryCreateDto {
    account: string;
    amount: number;
    subject?: ISalarySubject[];
    comment?: string;
}

export interface ISalaryCreateMultiDto {
    items: ISalaryCreateItemDto[];
    account: string;
    comment?: string;
}

export interface ISalaryCreateItemDto {
    amount: number;
    subject: ISalarySubject[];
    reservedSalaryId?: string; // id отложенной выплаты
}

export interface ISalaryReserveDto {
    amount: number;
    comment: string;
}

export interface ISalaryPaymentDto {
    account: string;
    description: string;
}

export interface ISalaryDeleteDto {
    deleteTransaction: boolean;
}

export type OrderWithSalaries = IOrder & { salaries: ISalary[] };
export type SaleWithSalaries = ISale & { salaries: ISalary[] };
