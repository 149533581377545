import { IOfficeUseCase, IOffice } from "@lib";
import { IOfficeCreateDto, IOfficeUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class OfficeUseCase implements IOfficeUseCase {
    public async select(companyId: string): Promise<IOffice[]> {
        try {
            const response = await Api.get<IOffice[]>(`/companies/${companyId}/offices`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IOffice> {
        try {
            const response = await Api.get<IOffice>(`/companies/${companyId}/offices/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IOfficeCreateDto): Promise<IOffice> {
        try {
            const response = await Api.post<IOffice>(`/companies/${companyId}/offices`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IOfficeUpdateDto): Promise<IOffice> {
        try {
            const response = await Api.put<IOffice>(`/companies/${companyId}/offices/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/offices/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
