import _Vue from "vue";

type Offset = {
    left?: string;
    top?: string;
    right?: string;
    bottom?: string;
};

export class AltSupportButton {
    private _widget: HTMLDivElement;

    private constructor(offsets: Offset) {
        this._widget = document.createElement("div");
        this._widget.id = "toggle_buttons_widget";
        this._widget.style.position = "fixed";
        this._widget.style.transform = "rotate(-90deg) scaleX(1)";
        const body = document.body || document.getElementsByTagName("body")[0];

        for (const key in offsets) {
            const offset = offsets[key as keyof Offset];
            if (offset) {
                this._widget.style[key as keyof Offset] = offset;
            }
        }

        body.appendChild(this._widget);
    }

    public static fromLeftTop(offsets: { left: string; top: string }): AltSupportButton {
        return new AltSupportButton(offsets);
    }

    public static fromLeftBottom(offsets: { left: string; bottom: string }): AltSupportButton {
        return new AltSupportButton(offsets);
    }

    public static fromRightTop(offsets: { right: string; top: string }): AltSupportButton {
        return new AltSupportButton(offsets);
    }

    public static fromRightBottom(offsets: { right: string; bottom: string }): AltSupportButton {
        return new AltSupportButton(offsets);
    }

    public addButton(widgetId: string, text: string): void {
        const button = new ToggleButton(widgetId, text);
        button.add(this._widget.id);
    }
}

class ToggleButton {
    private _widgetId: string;
    private _text: string;
    private _button: HTMLButtonElement;
    private _widgetVisibility: boolean = false;

    public constructor(widgetId: string, text: string) {
        this._widgetId = widgetId;
        this._text = text;

        this._button = document.createElement("button");
        this._button.id = `toggle_button_${widgetId}`;
        this._button.classList.add("btn", "btn-primary");
        this._button.style.position = "fixed";
        this._button.style.padding = "0.25rem 0.5rem";
        this._button.innerHTML = this._text;
        this._button.addEventListener("click", this._toggleButtonHandler.bind(this), false);
    }

    public add(parentId: string): void {
        const parent = document.getElementById(parentId);
        if (!parent) {
            return;
        }

        parent.appendChild(this._button);
    }

    private _toggleButtonHandler(): void {
        const widget = document.getElementById(this._widgetId);
        if (!widget) {
            return;
        }

        if (this._widgetVisibility) {
            widget.style.visibility = "hidden";
        } else {
            widget.style.visibility = "visible";
        }

        this._widgetVisibility = !this._widgetVisibility;
    }
}

type AltSupportWidgetOptions = {
    text: string;
    widgets: { id: string; text: string }[];
};

function createSupportWidget(options: AltSupportWidgetOptions): void {
    const altSupportButton = AltSupportButton.fromRightBottom({ right: "18px", bottom: "50px" });
    for (const widget of options.widgets) {
        altSupportButton.addButton(widget.id, options.text);
    }
}

export function AltSupportWidget(Vue: typeof _Vue, options: AltSupportWidgetOptions): void {
    if (document.readyState === "complete") {
        createSupportWidget(options);
    } else {
        window.addEventListener("load", () => createSupportWidget(options), false);
    }
}
