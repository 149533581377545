import { IReadQuery, ReadQuerySort, ITableFilter, ITableSort, ReadQueryFilter } from "@lib";

export class QueryConverter {
    public static objectToQuery(obj?: IReadQuery): any {
        if (!obj) {
            return undefined;
        }

        let query: any = {};

        if (obj.limit) {
            query.limit = obj.limit;
        }

        if (obj.offset) {
            query.offset = obj.offset;
        }

        if (obj.search) {
            query.search = obj.search;
        }

        if (obj.sort) {
            query.sort = this.sortToQuery(obj.sort);
        }

        if (obj.filter) {
            query = { ...query, ...this.filterToQuery(obj.filter) };
        }

        return query;
    }

    public static sortToQuery(sort?: ReadQuerySort[]): string | undefined {
        if (!sort) {
            return undefined;
        }

        let query: string = "";

        for (const elem of sort) {
            if (query.length > 0) {
                query += ",";
            }

            query += elem.order === "desc" ? `-${elem.field}` : elem.field;
        }

        return query.length > 0 ? query : undefined;
    }

    public static filterToQuery(filter?: ReadQueryFilter): any {
        if (!filter) {
            return undefined;
        }

        const query: any = {};

        // преобразуем фильтр к виду: cost[gt]=500&cost[lt]=1000
        for (const field in filter) {
            const fieldFilter = filter[field] as any;
            for (const operator in fieldFilter) {
                query[`${field}[${operator}]`] = fieldFilter[operator];
            }
        }

        return query;
    }

    public convertTableFilter(filter?: ITableFilter[]): any {
        if (!filter || filter.length === 0) {
            return undefined;
        }

        const query: any = {};
        // преобразуем фильтр к виду:
        filter.forEach(f => {
            if (f.operator === "in") {
                // поддержка условия: соответствует ли одному из элементов в массиве
                if (query[`${f.field}[${f.operator}]`]) {
                    query[`${f.field}[${f.operator}]`].push(f.value);
                } else {
                    query[`${f.field}[${f.operator}]`] = [f.value];
                }
            } else {
                query[`${f.field}[${f.operator}]`] = f.value;
            }
        });
        return query;
    }

    public convertTableSort(sort?: ITableSort[]): string | undefined {
        if (!sort || sort.length === 0) {
            return undefined;
        }

        let sortApi: string = "";
        for (const elem of sort) {
            if (sortApi.length > 0) {
                sortApi += ",";
            }
            sortApi += elem.sort === "desc" ? `-${elem.colId}` : elem.colId;
        }
        return sortApi.length > 0 ? sortApi : undefined;
    }

    public tableSortToQuerySort(tsort?: ITableSort[]): ReadQuerySort[] | undefined {
        if (!tsort) {
            return undefined;
        }

        return tsort.map(ts => ({
            field: ts.colId,
            order: ts.sort === "desc" ? "desc" : "asc",
        }));
    }

    public tableFilterToQueryFilter(tfilter?: ITableFilter[]): ReadQueryFilter | undefined {
        if (!tfilter) {
            return undefined;
        }

        const filter: ReadQueryFilter = {};

        for (const tf of tfilter) {
            if (tf.operator === "in") {
                if (!filter[tf.field]?.in) {
                    filter[tf.field] = { in: [] };
                }

                filter[tf.field]?.in?.push(tf.value);
            } else {
                if (!filter[tf.field]) {
                    filter[tf.field] = {};
                }

                (filter[tf.field] as any)[tf.operator] = tf.value;
            }
        }

        return filter;
    }
}
