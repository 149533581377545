import { IProjectUseCase, IProject } from "@lib";
import { IProjectCreateDto, IProjectUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class ProjectUseCase implements IProjectUseCase {
    public async select(companyId: string): Promise<IProject[]> {
        try {
            const response = await Api.get<IProject[]>(`/companies/${companyId}/projects`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IProject> {
        try {
            const response = await Api.get<IProject>(`/companies/${companyId}/projects/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IProjectCreateDto): Promise<IProject> {
        try {
            const response = await Api.post<IProject>(`/companies/${companyId}/projects`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IProjectUpdateDto): Promise<IProject> {
        try {
            const response = await Api.put<IProject>(`/companies/${companyId}/projects/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/projects/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
