/** Дополнительная функция. */
export enum CompanyFeature {
    /** Филиалы. */
    Offices = "offices",
    /** Магазины. */
    Shops = "shops",
    /** Проекты. */
    Projects = "projects",
    /** Штрихкоды. */
    Barcodes = "barcodes",
}
