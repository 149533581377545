import { IPluginUseCase, IPluginNotificationSendDto, IPluginNotificationSendResultDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class PluginUseCase implements IPluginUseCase {
    public async notificationSend(
        companyId: string,
        bindingId: string,
        dto: IPluginNotificationSendDto,
    ): Promise<IPluginNotificationSendResultDto> {
        try {
            const response = await Api.post<IPluginNotificationSendResultDto>(
                `/companies/${companyId}/plugins/${bindingId}/notification/send`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    // public async notificationCost(
    //     companyId: string,
    //     bindingId: string,
    //     dto: IPluginNotificationSendDto,
    // ): Promise<IPluginNotificationSendResultDto> {
    //     try {
    //         const response = await Api.post<IPluginNotificationSendResultDto>(
    //             `/companies/${companyId}/plugins/${bindingId}/notification/cost`,
    //             dto,
    //         );
    //         return response.data;
    //     } catch (e: any) {
    //         throw new DataLoadException(e);
    //     }
    // }
}
