import {
    ICompanyFeatureBarcodesUseCase,
    ICompanyFeatureBarcodes,
    IBarcode,
    BarcodeType,
    ICompanyFeatureBarcodesValidationResult,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class CompanyFeatureBarcodesUseCase implements ICompanyFeatureBarcodesUseCase {
    public async get(companyId: string): Promise<ICompanyFeatureBarcodes | null> {
        try {
            const response = await Api.get<ICompanyFeatureBarcodes | null>(`/companies/${companyId}/features/barcodes`);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async generate(companyId: string, type: BarcodeType): Promise<IBarcode> {
        try {
            const params = { type };
            const response = await Api.get<IBarcode>(`/companies/${companyId}/features/barcodes/generate`, {
                params,
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async validate(
        companyId: string,
        type: BarcodeType,
        code: string,
    ): Promise<ICompanyFeatureBarcodesValidationResult> {
        try {
            const params = { type, code };
            const response = await Api.get<ICompanyFeatureBarcodesValidationResult>(
                `/companies/${companyId}/features/barcodes/validate`,
                { params },
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, dto: ICompanyFeatureBarcodes): Promise<void> {
        try {
            await Api.put<void>(`/companies/${companyId}/features/barcodes`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
