/** Разделы разрешений. */
export enum PermissionCommonSection {
    // /** Администратор */
    // Admin       = "admin",

    /** Разрешения на просмотр/изменение компании. */
    Company     = "company",

    /** Разрешения на просмотр/изменение клиентов. */
    Clients     = "clients",

    /** Разрешения на просмотр/создание выплат. */
    Salaries    = "salaries",

    /** Разрешения на просмотр/изменение сотрудников. */
    Employees   = "employees",

    /** Разрешения на просмотр/изменение филиалов. */
    Offices     = "offices",

    /** Разрешения на просмотр/изменение магазинов. */
    Shops       = "shops",

    /** Разрешения на просмотр/изменение проектов. */
    Projects    = "projects",

    /** Разрешения на просмотр/изменение счетов. */
    Accounts    = "accounts",

    /** Разрешения на просмотр/изменение складов. */
    Stores      = "stores",

    /** Разрешения на просмотр/изменение списков. */
    Lists       = "lists",

    /** Разрешения на просмотр/изменение шаблонов. */
    Templates   = "templates",

    /** Разрешения на просмотр отчётов. */
    Reports     = "reports",
}
