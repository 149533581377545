import { ITemplateNotificationUseCase, ITemplateNotification, TemplateNotificationType } from "@lib";
import {
    ITemplateNotificationCreateDto,
    ITemplateNotificationUpdateDto,
    ITemplateNotificationUpdateTemplateDto,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class TemplateNotificationUseCase implements ITemplateNotificationUseCase {
    public async select(companyId: string, type?: TemplateNotificationType): Promise<ITemplateNotification[]> {
        try {
            const params = { type };
            const response = await Api.get<ITemplateNotification[]>(`/companies/${companyId}/templates/notifications`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ITemplateNotification> {
        try {
            const response = await Api.get<ITemplateNotification>(
                `/companies/${companyId}/templates/notifications/${id}`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ITemplateNotificationCreateDto): Promise<ITemplateNotification> {
        try {
            const response = await Api.post<ITemplateNotification>(
                `/companies/${companyId}/templates/notifications`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(
        companyId: string,
        dtos: ITemplateNotificationCreateDto[],
    ): Promise<ITemplateNotification[]> {
        try {
            const response = await Api.post<ITemplateNotification[]>(
                `/companies/${companyId}/templates/notifications/many`,
                dtos,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(
        companyId: string,
        id: string,
        dto: ITemplateNotificationUpdateDto,
    ): Promise<ITemplateNotification> {
        try {
            const response = await Api.put<ITemplateNotification>(
                `/companies/${companyId}/templates/notifications/${id}`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async updateTemplate(
        companyId: string,
        id: string,
        dto: ITemplateNotificationUpdateTemplateDto,
    ): Promise<ITemplateNotification> {
        try {
            const response = await Api.patch<ITemplateNotification>(
                `/companies/${companyId}/templates/notifications/${id}/template`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/templates/notifications/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
