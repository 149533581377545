import { ICompanyPricingUseCase } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class CompanyPricingUseCase implements ICompanyPricingUseCase {
    /** Check operation 'Create Order' due to pricing plan. */
    public async checkCreateOrder(companyId: string): Promise<boolean> {
        try {
            const response = await Api.get<boolean>(`/companies/${companyId}/pricing/order`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    /** Check operation 'Create Sale' due to pricing plan. */
    public async checkCreateSale(companyId: string): Promise<boolean> {
        try {
            const response = await Api.get<boolean>(`/companies/${companyId}/pricing/sale`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
