export enum ApiError {
    SUCCESS                         = 0,
    
    EMAIL_ALREADY_EXISTS            = 1,
    INVALID_LOGIN_OR_PASSWORD       = 2,
    INVALID_EMAIL                   = 3,
    INVALID_USER                    = 4,
    INVALID_TOKEN                   = 5,
    INVALID_OLD_PASSWORD            = 6,
    PASSWORDS_NOT_MATCHED           = 7,
    
    SOCIAL_NOT_FOUND                = 10,
    SOCIAL_UNSUPPORTED              = 11,
    SOCIAL_ALREADY_EXISTS           = 12,
    
    USER_NOT_FOUND                  = 20,
    USER_UNABLE_CREATE              = 21,
    USER_UNABLE_UPDATE              = 22,

    EMPLOYEE_NOT_FOUND              = 25,
    EMPLOYEE_UNABLE_CREATE          = 26,
    EMPLOYEE_UNABLE_UPDATE          = 27,
    EMPLOYEE_UNABLE_DELETE          = 28,
    
    COMPANY_NOT_FOUND               = 30,
    COMPANY_UNABLE_CREATE           = 31,
    COMPANY_UNABLE_UPDATE           = 32,
    COMPANY_PAYMENT_UNABLE_CREATE   = 36,
    COMPANY_PAYMENT_FUNDS           = 37,
    COMPANY_PRICING_UNABLE_UPDATE   = 38,

    PERMISSIONS_NOT_FOUND           = 40,
    PERMISSIONS_UNABLE_GRANT        = 41,

    CLIENT_NOT_FOUND                = 50,
    CLIENT_UNABLE_CREATE            = 51,
    CLIENT_UNABLE_UPDATE            = 52,
    CLIENT_UNABLE_DELETE            = 53,
    CLIENT_UNABLE_IMPORT            = 59,

    // office - 10xxx

    OFFICE_NOT_FOUND                = 10000,
    OFFICE_UNABLE_CREATE            = 10001,
    OFFICE_UNABLE_UPDATE            = 10002,
    OFFICE_UNABLE_DELETE            = 10003,

    ORDER_NOT_FOUND                 = 10100,
    ORDER_UNABLE_CREATE             = 10101,
    ORDER_UNABLE_UPDATE             = 10102,
    ORDER_UNABLE_DELETE             = 10103,

    ORDER_STAGE_NOT_FOUND           = 10500,
    ORDER_STAGE_UNABLE_CREATE       = 10501,
    ORDER_STAGE_UNABLE_UPDATE       = 10502,
    ORDER_STAGE_UNABLE_DELETE       = 10503,

    ORDER_TYPE_NOT_FOUND            = 10600,
    ORDER_TYPE_UNABLE_CREATE        = 10601,
    ORDER_TYPE_UNABLE_UPDATE        = 10602,
    ORDER_TYPE_UNABLE_DELETE        = 10603,

    PRODUCT_TYPE_NOT_FOUND          = 10700,
    PRODUCT_TYPE_UNABLE_CREATE      = 10701,
    PRODUCT_TYPE_UNABLE_UPDATE      = 10702,
    PRODUCT_TYPE_UNABLE_DELETE      = 10703,

    WORK_NOT_FOUND                  = 10800,
    WORK_UNABLE_CREATE              = 10801,
    WORK_UNABLE_UPDATE              = 10802,
    WORK_UNABLE_DELETE              = 10803,
    WORK_UNABLE_IMPORT              = 10804,
    WORK_UNABLE_EXPORT              = 10805,

    // shop - 11xxx

    SHOP_NOT_FOUND                  = 11000,
    SHOP_UNABLE_CREATE              = 11001,
    SHOP_UNABLE_UPDATE              = 11002,
    SHOP_UNABLE_DELETE              = 11003,

    SALE_NOT_FOUND                  = 11100,
    SALE_UNABLE_CREATE              = 11101,
    SALE_UNABLE_UPDATE              = 11102,
    SALE_UNABLE_DELETE              = 11103,

    SALE_STAGE_NOT_FOUND            = 11500,
    SALE_STAGE_UNABLE_CREATE        = 11501,
    SALE_STAGE_UNABLE_UPDATE        = 11502,
    SALE_STAGE_UNABLE_DELETE        = 11503,

    SALE_TYPE_NOT_FOUND             = 11600,
    SALE_TYPE_UNABLE_CREATE         = 11601,
    SALE_TYPE_UNABLE_UPDATE         = 11602,
    SALE_TYPE_UNABLE_DELETE         = 11603,

    // projects - 12xxx

    MODULE_NOT_FOUND                = 12000,

    PROJECT_NOT_FOUND               = 12100,
    PROJECT_UNABLE_CREATE           = 12101,
    PROJECT_UNABLE_UPDATE           = 12102,
    PROJECT_UNABLE_DELETE           = 12103,

    PROJECT_MODULE_NOT_FOUND        = 12200,
    PROJECT_MODULE_UNABLE_CREATE    = 12201,
    PROJECT_MODULE_UNABLE_UPDATE    = 12202,
    PROJECT_MODULE_UNABLE_DELETE    = 12203,

    TASK_NOT_FOUND                  = 12300,
    TASK_UNABLE_CREATE              = 12301,
    TASK_UNABLE_UPDATE              = 12302,
    TASK_UNABLE_DELETE              = 12303,

    TASK_TEMPLATE_NOT_FOUND         = 12400,
    TASK_TEMPLATE_UNABLE_CREATE     = 12401,
    TASK_TEMPLATE_UNABLE_UPDATE     = 12402,
    TASK_TEMPLATE_UNABLE_DELETE     = 12403,

    TASK_STAGE_NOT_FOUND            = 12500,
    TASK_STAGE_UNABLE_CREATE        = 12501,
    TASK_STAGE_UNABLE_UPDATE        = 12502,
    TASK_STAGE_UNABLE_DELETE        = 12503,

    // account - 15xxx

    ACCOUNT_NOT_FOUND               = 15000,
    ACCOUNT_UNABLE_CREATE           = 15001,
    ACCOUNT_UNABLE_UPDATE           = 15002,
    ACCOUNT_UNABLE_DELETE           = 15003,

    TRANSACTION_NOT_FOUND           = 15100,
    TRANSACTION_UNABLE_CREATE       = 15101,
    TRANSACTION_UNABLE_UPDATE       = 15102,
    TRANSACTION_UNABLE_TRANSFER     = 15103,
    TRANSACTION_UNABLE_DELETE       = 15104,

    TRANSACTION_ITEM_NOT_FOUND      = 15300,
    TRANSACTION_ITEM_UNABLE_CREATE  = 15301,
    TRANSACTION_ITEM_UNABLE_UPDATE  = 15302,
    TRANSACTION_ITEM_UNABLE_DELETE  = 15303,

    // store - 16xxx

    STORE_NOT_FOUND                 = 16000,
    STORE_UNABLE_CREATE             = 16001,
    STORE_UNABLE_UPDATE             = 16002,
    STORE_UNABLE_DELETE             = 16003,

    GOOD_NOT_FOUND                  = 16100,
    GOOD_UNABLE_CREATE              = 16101,
    GOOD_UNABLE_UPDATE              = 16102,
    GOOD_UNABLE_DELETE              = 16103,
    GOOD_UNABLE_IMPORT              = 16104,

    GOOD_CATEGORY_NOT_FOUND         = 16300,
    GOOD_CATEGORY_UNABLE_CREATE     = 16301,
    GOOD_CATEGORY_UNABLE_UPDATE     = 16302,
    GOOD_CATEGORY_UNABLE_DELETE     = 16303,

    GOOD_REGISTRATION_NOT_FOUND     = 16400,
    GOOD_REGISTRATION_UNABLE_CREATE = 16401,
    GOOD_REGISTRATION_UNABLE_UPDATE = 16402,
    GOOD_REGISTRATION_UNABLE_DELETE = 16403,

    GOOD_WRITE_OFF_NOT_FOUND        = 16500,
    GOOD_WRITE_OFF_UNABLE_CREATE    = 16501,
    GOOD_WRITE_OFF_UNABLE_UPDATE    = 16502,
    GOOD_WRITE_OFF_UNABLE_DELETE    = 16503,

    GOOD_INVENTORY_NOT_FOUND        = 16600,
    GOOD_INVENTORY_UNABLE_CREATE    = 16601,
    GOOD_INVENTORY_UNABLE_UPDATE    = 16602,
    GOOD_INVENTORY_UNABLE_DELETE    = 16603,

    GOOD_INVENTORY_ITEM_NOT_FOUND       = 16700,
    GOOD_INVENTORY_ITEM_UNABLE_CREATE   = 16701,
    GOOD_INVENTORY_ITEM_UNABLE_UPDATE   = 16702,
    GOOD_INVENTORY_ITEM_UNABLE_DELETE   = 16703,

    SUPPLIER_NOT_FOUND              = 16800,
    SUPPLIER_UNABLE_CREATE          = 16801,
    SUPPLIER_UNABLE_UPDATE          = 16802,
    SUPPLIER_UNABLE_DELETE          = 16803,

    // template - 20xxx

    TEMPLATE_DOCUMENT_NOT_FOUND     = 20100,
    TEMPLATE_DOCUMENT_UNABLE_CREATE = 20101,
    TEMPLATE_DOCUMENT_UNABLE_UPDATE = 20102,
    TEMPLATE_DOCUMENT_UNABLE_DELETE = 20103,

    TEMPLATE_NOTIFICATION_NOT_FOUND     = 20200,
    TEMPLATE_NOTIFICATION_UNABLE_CREATE = 20201,
    TEMPLATE_NOTIFICATION_UNABLE_UPDATE = 20202,
    TEMPLATE_NOTIFICATION_UNABLE_DELETE = 20203,

    TEMPLATE_LABEL_NOT_FOUND        = 20300,
    TEMPLATE_LABEL_UNABLE_CREATE    = 20301,
    TEMPLATE_LABEL_UNABLE_UPDATE    = 20302,
    TEMPLATE_LABEL_UNABLE_DELETE    = 20303,

    // plugins - 25xxx

    PLUGIN_NOT_FOUND                = 25000,
    PLUGIN_UNABLE_CREATE            = 25001,
    PLUGIN_UNABLE_UPDATE            = 25002,
    PLUGIN_UNABLE_DELETE            = 25003,
    PLUGIN_UNABLE_BIND              = 25004,

    // salary - 30xxx

    SALARY_NOT_FOUND                = 30000,
    SALARY_UNABLE_CREATE            = 30001,
    SALARY_UNABLE_UPDATE            = 30002,
    SALARY_UNABLE_DELETE            = 30003,

    // reports - 31xxx

    REPORT_NOT_FOUND                = 31000,

    //

    CLIENT_SOURCE_NOT_FOUND         = 170,
    CLIENT_SOURCE_UNABLE_CREATE     = 171,

    PARTNER_NOT_FOUND               = 185,

    SETTINGS_TABLE_NOT_FOUND        = 200,
    SETTINGS_TABLE_UNABLE_CREATE    = 201,
    SETTINGS_FORM_NOT_FOUND         = 205,
    SETTINGS_FORM_UNABLE_CREATE     = 206,
    SETTINGS_USER_NOT_FOUND         = 210,
    SETTINGS_USER_UNABLE_CREATE     = 211,

    PRICING_INVALID_OPERATION       = 240,

    TRACKING_CODE_NOT_FOUND         = 250,

    UNKNOWN_ERROR                   = 255,

    HTTP_BADREQUEST                 = 400,
    HTTP_UNAUTHORIZED               = 401,
    HTTP_FORBIDDEN                  = 403,
    HTTP_NOT_FOUND                  = 404,
    HTTP_INTERNAL                   = 500,
}
