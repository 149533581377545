import { IAuthCompanyUseCase } from "@lib";
import { IAuthRegistrationDto, IAuthRegistrationResultDto } from "@lib";
import { IAuthLoginDto, IAuthLoginResultDto } from "@lib";
import { IAuthRefreshResultDto, IAuthLoadInfoResultDto } from "@lib";
import { IAuthResetPasswordDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api, ApiAuth } from "../axios";

export class AuthCompanyUseCase implements IAuthCompanyUseCase {
    public async register(dto: IAuthRegistrationDto): Promise<IAuthRegistrationResultDto> {
        try {
            const response = await ApiAuth.post<IAuthRegistrationResultDto>("/auth/registration", dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async login(dto: IAuthLoginDto): Promise<IAuthLoginResultDto> {
        try {
            const response = await ApiAuth.post<IAuthLoginResultDto>("/auth/login", dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async logout(): Promise<void> {
        try {
            await ApiAuth.post<void>("/auth/logout");
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async logoutCompany(): Promise<IAuthLoginResultDto> {
        try {
            const response = await Api.post<IAuthLoginResultDto>("/auth/logout-company");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async refresh(): Promise<IAuthRefreshResultDto> {
        try {
            const response = await ApiAuth.post<IAuthRefreshResultDto>("/auth/refresh");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    /** Отправка подтверждения почты (со страницы профиля). */
    public async sendConfirmationEmail(): Promise<void> {
        try {
            await Api.post("/auth/confirm-email-send");
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async confirmEmail(token: string): Promise<void> {
        try {
            await ApiAuth.post("/auth/confirm-email", { token });
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async sendResetPasswordEmail(email: string): Promise<void> {
        try {
            await ApiAuth.post("/auth/reset-password-send", { email });
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async resetPassword(dto: IAuthResetPasswordDto): Promise<void> {
        try {
            await ApiAuth.post<IAuthLoadInfoResultDto>("/auth/reset-password", dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    /** Загрузка данных пользователя/компании при обновлении страницы. */
    public async loadInfo(): Promise<IAuthLoadInfoResultDto> {
        try {
            const response = await Api.get<IAuthLoadInfoResultDto>("/auth/info");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
