/** Тип элемента временной шкалы (истории). */
export enum TimelineStepType {
    /** Создание. */
    Create = "create",

    /** Удаление. */
    Delete = "delete",

    /** Выполнение. */
    Done = "done",

    /** Повторное открытие. */
    Reopen = "reopen",

    /** Комментарий. */
    Comment = "comment",

    /** Уведомление по смс. */
    NotificationSms = "notification-sms",

    /** Изменение номера. */
    NumberChanged = "number",

    /** Изменение информации. */
    InfoChanged = "info",

    /** Изменение этапа. */
    StageChanged = "stage",

    /** Изменение клиента. */
    ClientChanged = "client",

    /** Изменение ответственного. */
    ManagerChanged = "manager",

    /** Изменение продавца. */
    SellerChanged = "seller",

    /** Изменение сотрудника. */
    EmployeeChanged = "employee",

    /** Изменение продукта (устройства). */
    ProductChanged = "product",

    /** Платёж. */
    PaymentCreate = "payment",

    /** Удаление платежа. */
    PaymentDelete = "payment-delete",

    /** Удаление нескольких платежей. */
    PaymentDeleteMany = "payment-delete-many",

    /** Создание работы. */
    WorkCreate = "work-create",

    /** Обновление работы. */
    WorkUpdate = "work-update",

    /** Удаление работы. */
    WorkDelete = "work-delete",

    /** Создание материала. */
    MaterialCreate = "material-create",

    /** Взятие материала со склада. */
    MaterialGet = "material-get",

    /** Обновление материала. */
    MaterialUpdate = "material-update",

    /** Удаление материала. */
    MaterialDelete = "material-delete",

    /** Создание задачи. */
    TaskCreate = "task-create",

    /** Обновление задачи. */
    TaskUpdate = "task-update",

    /** Перемещение задачи. */
    TaskMove = "task-move",

    /** Удаление задачи. */
    TaskDelete = "task-delete",

    /** Создание товара. */
    GoodCreate = "good-create",

    /** Взятие товара со склада. */
    GoodFromStore = "good-get",

    /** Обновление товара. */
    GoodUpdate = "good-update",

    /** Обновление нескольких товаров. */
    GoodUpdateMany = "good-update-many",

    /** Удаление товара. */
    GoodDelete = "good-delete",

    /** Удаление нескольких товаров. */
    GoodDeleteMany = "good-delete-many",
}

// TODO: подумать, какие ещё могут быть типы
