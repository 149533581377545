import { ISalaryUseCase, ISalary, ISalaryReserveDto, ISalaryDeleteDto } from "@lib";
import { OrderWithSalaries, SaleWithSalaries } from "@lib";
import { ISalarySelectDto, OrderWithSalariesDto, SaleWithSalariesDto } from "@lib";
import { ISalaryCreateDto, ISalaryCreateMultiDto, ISalaryPaymentDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class SalaryUseCase implements ISalaryUseCase {
    public async selectPaid(companyId: string, employeeId: string, dto: ISalarySelectDto): Promise<ISalary[]> {
        try {
            const params = dto;
            const response = await Api.get<ISalary[]>(`/companies/${companyId}/employees/${employeeId}/salaries/paid`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectReserved(companyId: string, employeeId: string): Promise<ISalary[]> {
        try {
            const response = await Api.get<ISalary[]>(
                `/companies/${companyId}/employees/${employeeId}/salaries/reserved`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectOrders(
        companyId: string,
        employeeId: string,
        dto: ISalarySelectDto,
    ): Promise<OrderWithSalariesDto[]> {
        try {
            const params = dto;
            const response = await Api.get<OrderWithSalaries[]>(
                `/companies/${companyId}/employees/${employeeId}/salaries/orders`,
                { params },
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectSales(
        companyId: string,
        employeeId: string,
        dto: ISalarySelectDto,
    ): Promise<SaleWithSalariesDto[]> {
        try {
            const params = dto;
            const response = await Api.get<SaleWithSalaries[]>(
                `/companies/${companyId}/employees/${employeeId}/salaries/sales`,
                { params },
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(
        companyId: string,
        employeeId: string,
        salaryDto: ISalaryCreateDto,
        paymentDto: ISalaryPaymentDto,
    ): Promise<ISalary> {
        try {
            const dto = { salary: salaryDto, payment: paymentDto };
            const response = await Api.post<ISalary>(`/companies/${companyId}/employees/${employeeId}/salaries`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMulti(
        companyId: string,
        employeeId: string,
        salariesDto: ISalaryCreateMultiDto,
        paymentDto: ISalaryPaymentDto,
    ): Promise<ISalary> {
        try {
            const dto = { salaries: salariesDto, payment: paymentDto };
            const response = await Api.post<ISalary>(
                `/companies/${companyId}/employees/${employeeId}/salaries/multi`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async reserve(companyId: string, employeeId: string, dto: ISalaryReserveDto): Promise<ISalary> {
        try {
            const response = await Api.post<ISalary>(
                `/companies/${companyId}/employees/${employeeId}/salaries/reserve`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async pay(
        companyId: string,
        employeeId: string,
        salaryId: string,
        paymentDto: ISalaryPaymentDto,
    ): Promise<ISalary> {
        try {
            const dto = paymentDto;
            const response = await Api.put<ISalary>(
                `/companies/${companyId}/employees/${employeeId}/salaries/${salaryId}/pay`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, employeeId: string, salaryId: string, dto: ISalaryDeleteDto): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/employees/${employeeId}/salaries/${salaryId}`, {
                data: dto,
            });
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
