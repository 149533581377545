import { ISettingsFormUseCase, IForm, FormType } from "@lib";
import { ISettingsFormUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class SettingsFormUseCase implements ISettingsFormUseCase {
    public async get(companyId: string, type: FormType): Promise<IForm> {
        try {
            const response = await Api.get<IForm>(`/companies/${companyId}/settings/forms/${type}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, type: FormType, dto: ISettingsFormUpdateDto): Promise<IForm> {
        try {
            const response = await Api.put<IForm>(`/companies/${companyId}/settings/forms/${type}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
