import { ISaleStageUseCase, ISaleStage } from "@lib";
import { ISaleStageCreateDto, ISaleStageUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class SaleStageUseCase implements ISaleStageUseCase {
    public async select(companyId: string): Promise<ISaleStage[]> {
        try {
            const response = await Api.get<ISaleStage[]>(`/companies/${companyId}/lists/salestages`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ISaleStage> {
        try {
            const response = await Api.get<ISaleStage>(`/companies/${companyId}/lists/salestages/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ISaleStageCreateDto): Promise<ISaleStage> {
        try {
            const response = await Api.post<ISaleStage>(`/companies/${companyId}/lists/salestages`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: ISaleStageCreateDto[]): Promise<ISaleStage[]> {
        try {
            const response = await Api.post<ISaleStage[]>(`/companies/${companyId}/lists/salestages/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: ISaleStageUpdateDto): Promise<ISaleStage> {
        try {
            const response = await Api.put<ISaleStage>(`/companies/${companyId}/lists/salestages/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/salestages/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
