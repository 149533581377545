import {
    IGoodRegistration,
    IGoodRegistrationUseCase,
    IGoodRegistrationCreateDto,
    IGoodRegistrationUpdateDto,
    IReadQuery,
    ISelectedData,
} from "@lib";
import { Api } from "../axios";
import { DataLoadException } from "@/core/exceptions";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class GoodRegistrationUseCase implements IGoodRegistrationUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<IGoodRegistration>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGoodRegistration>>(
                `/companies/${companyId}/good-registrations`,
                { params },
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, registrationId: string): Promise<IGoodRegistration> {
        try {
            const response = await Api.get<IGoodRegistration>(
                `/companies/${companyId}/good-registrations/${registrationId}`,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IGoodRegistrationCreateDto): Promise<IGoodRegistration> {
        try {
            const response = await Api.post<IGoodRegistration>(`/companies/${companyId}/good-registrations/`, dto);

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(
        companyId: string,
        registrationId: string,
        dto: IGoodRegistrationUpdateDto,
    ): Promise<IGoodRegistration> {
        try {
            const response = await Api.put<IGoodRegistration>(
                `/companies/${companyId}/good-registrations/${registrationId}`,
                dto,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, registrationId: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/good-registrations/${registrationId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
