import { IPartnerUseCase, IPartner } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class PartnerUseCase implements IPartnerUseCase {
    public async getByCompany(companyId: string): Promise<IPartner | null> {
        try {
            const response = await Api.get<IPartner | null>(`/companies/${companyId}/partner`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string): Promise<IPartner> {
        try {
            const response = await Api.post<IPartner>(`/companies/${companyId}/partner`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
