import { IShopUseCase, IShop } from "@lib";
import { IShopCreateDto, IShopUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class ShopUseCase implements IShopUseCase {
    public async select(companyId: string): Promise<IShop[]> {
        try {
            const response = await Api.get<IShop[]>(`/companies/${companyId}/shops`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IShop> {
        try {
            const response = await Api.get<IShop>(`/companies/${companyId}/shops/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IShopCreateDto): Promise<IShop> {
        try {
            const response = await Api.post<IShop>(`/companies/${companyId}/shops`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IShopUpdateDto): Promise<IShop> {
        try {
            const response = await Api.put<IShop>(`/companies/${companyId}/shops/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/shops/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
