import { IStoreUseCase, IStore } from "@lib";
import { IStoreCreateDto, IStoreUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class StoreUseCase implements IStoreUseCase {
    public async select(companyId: string): Promise<IStore[]> {
        try {
            const response = await Api.get<IStore[]>(`/companies/${companyId}/stores`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IStore> {
        try {
            const response = await Api.get<IStore>(`/companies/${companyId}/stores/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: IStoreCreateDto): Promise<IStore> {
        try {
            const response = await Api.post<IStore>(`/companies/${companyId}/stores/`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IStoreUpdateDto): Promise<IStore> {
        try {
            const response = await Api.put<IStore>(`/companies/${companyId}/stores/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/stores/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
