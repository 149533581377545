import {
    ITaskUseCase,
    ITask,
    ISelectedData,
    ITaskCreateDto,
    ITaskDeleteManyDto,
    ITaskDeleteDto,
    ITaskInfoChangeDto,
    IReadQuery,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class TaskUseCase implements ITaskUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<ITask>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<ITask>>(`/companies/${companyId}/tasks`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ITask> {
        try {
            const response = await Api.get<ITask>(`/companies/${companyId}/tasks/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ITaskCreateDto): Promise<ITask> {
        try {
            const response = await Api.post<ITask>(`/companies/${companyId}/tasks`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeNumber(companyId: string, taskId: string, number: number): Promise<ITask> {
        try {
            const dto = { number };
            const response = await Api.patch<ITask>(`/companies/${companyId}/tasks/${taskId}/number`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeInfo(companyId: string, taskId: string, infoDto: ITaskInfoChangeDto): Promise<ITask> {
        try {
            const dto = { info: infoDto };
            const response = await Api.patch<ITask>(`/companies/${companyId}/tasks/${taskId}/info`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeStage(companyId: string, taskId: string, stageId: string, comment?: string): Promise<ITask> {
        try {
            const dto = { stage: stageId, comment };
            const response = await Api.patch<ITask>(`/companies/${companyId}/tasks/${taskId}/stage`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeEmployees(companyId: string, taskId: string, employeeIds: string[]): Promise<ITask> {
        try {
            const dto = { employees: employeeIds };
            const response = await Api.patch<ITask>(`/companies/${companyId}/tasks/${taskId}/seller`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async addComment(companyId: string, taskId: string, comment: string): Promise<ITask> {
        try {
            const dto = { comment };
            const response = await Api.post<ITask>(`/companies/${companyId}/tasks/${taskId}/comments`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async complete(
        companyId: string,
        taskId: string,
        stageId: string,
        infoDto?: ITaskInfoChangeDto,
    ): Promise<ITask> {
        try {
            const dto = { stage: stageId, info: infoDto };
            const response = await Api.patch<ITask>(`/companies/${companyId}/tasks/${taskId}/complete`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async reopen(companyId: string, taskId: string, stageId: string): Promise<ITask> {
        try {
            const dto = { stage: stageId };
            const response = await Api.patch<ITask>(`/companies/${companyId}/tasks/${taskId}/reopen`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string, dto: ITaskDeleteDto): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/tasks/${id}`, { data: dto });
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: ITaskDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/tasks/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
