import { ITemplateDocumentUseCase, ITemplateDocument, TemplateDocumentType } from "@lib";
import { ITemplateDocumentCreateDto, ITemplateDocumentCreateFromTemplateDto } from "@lib";
import { ITemplateDocumentUpdateDto, ITemplateDocumentUpdateTemplateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class TemplateDocumentUseCase implements ITemplateDocumentUseCase {
    public async select(companyId: string, type?: TemplateDocumentType): Promise<ITemplateDocument[]> {
        try {
            const params = { type };
            const response = await Api.get<ITemplateDocument[]>(`/companies/${companyId}/templates/documents`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ITemplateDocument> {
        try {
            const response = await Api.get<ITemplateDocument>(`/companies/${companyId}/templates/documents/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ITemplateDocumentCreateDto): Promise<ITemplateDocument> {
        try {
            const response = await Api.post<ITemplateDocument>(`/companies/${companyId}/templates/documents`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: ITemplateDocumentCreateDto[]): Promise<ITemplateDocument[]> {
        try {
            const response = await Api.post<ITemplateDocument[]>(
                `/companies/${companyId}/templates/documents/many`,
                dtos,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createFromTemplate(
        companyId: string,
        dto: ITemplateDocumentCreateFromTemplateDto,
    ): Promise<ITemplateDocument> {
        try {
            const response = await Api.post<ITemplateDocument>(
                `/companies/${companyId}/templates/documents/template`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createManyFromTemplate(
        companyId: string,
        dtos: ITemplateDocumentCreateFromTemplateDto[],
    ): Promise<ITemplateDocument[]> {
        try {
            const response = await Api.post<ITemplateDocument[]>(
                `/companies/${companyId}/templates/documents/many/template`,
                dtos,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: ITemplateDocumentUpdateDto): Promise<ITemplateDocument> {
        try {
            const response = await Api.put<ITemplateDocument>(`/companies/${companyId}/templates/documents/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async updateTemplate(
        companyId: string,
        id: string,
        dto: ITemplateDocumentUpdateTemplateDto,
    ): Promise<ITemplateDocument> {
        try {
            const response = await Api.patch<ITemplateDocument>(
                `/companies/${companyId}/templates/documents/${id}/template`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/templates/documents/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
