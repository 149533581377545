import Vue from "vue";
import Router from "vue-router";
import { MenuItems } from "../layouts/components/sidebar/menu-items";

Vue.use(Router);

enum AuthType {
    Public = "public",
    Unauth = "unauth",
    Company = "company",
    UserOnly = "user-only",
}

export enum MainPages {
    /** Раздел "Задачи". */
    Tasks = "tasks",

    /** Раздел "Склад". Товары. */
    StoreGoods = "stores",

    /** Раздел "Склад". Оприходования. */
    StoreGoodRegistrations = "goodRegistrations",

    /** Раздел "Склад". Списания. */
    StoreGoodWriteOffs = "goodWriteOffs",

    /** Раздел "Склад". Инвентаризации. */
    StoreGoodInventories = "goodInventories",
}

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        // =============================================================================
        // PUBLIC PAGES
        // =============================================================================
        {
            path: "",
            component: () => import("@/layouts/full-page/index.vue"),
            children: [
                {
                    path: "/",
                    name: "landing",
                    component: () => import("@/views/community/landing.vue"),
                    meta: {
                        auth: AuthType.Public,
                    },

                    // TODO: с лендинга перекидывать на страницу логина
                    redirect: { name: "login" },
                },
                {
                    path: "/documents/eula",
                    name: "eula",
                    component: () => import("@/views/community/eula.vue"),
                    meta: {
                        title: "Лицензионный договор - оферта",
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/documents/legal",
                    name: "legal",
                    component: () => import("@/views/community/legal.vue"),
                    meta: {
                        title: "Политика в отношении обработки персональных данных",
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/tracking/:code?",
                    name: "tracking",
                    component: () => import("@/views/community/tracking/tracking.vue"),
                    meta: {
                        title: "Отслеживание",
                        auth: AuthType.Public,
                    },
                },
            ],
        },

        // =============================================================================
        // USER PAGES
        // =============================================================================
        {
            path: "",
            component: () => import("@/layouts/main/index.vue"),
            props: { menuItems: MenuItems },
            children: [
                {
                    path: "/home",
                    name: "home",
                    component: () => import("@/views/main/home/home.vue"),
                    meta: {
                        title: "Главная",
                        auth: AuthType.Company,
                    },

                    // TODO: пока нет главной страницы, переходить на страницу заявок
                    redirect: { name: "orders" },
                },
                {
                    path: "/orders/:id?",
                    name: "orders",
                    component: () => import("@/views/main/orders/orders.vue"),
                    meta: {
                        title: "Заявки",
                        active: "orders",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/sales/:id?",
                    name: "sales",
                    component: () => import("@/views/main/sales/sales.vue"),
                    meta: {
                        title: "Магазин",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/tasks/:id?",
                    name: MainPages.Tasks,
                    component: () => import("@/views/main/projects/tasks/index.vue"),
                    meta: {
                        title: "Задачи",
                        //active: MainPages.Tasks,
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/accounts/:id?",
                    name: "accounts",
                    component: () => import("@/views/main/accounts/accounts.vue"),
                    meta: {
                        title: "Финансы",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/stores/:id?",
                    name: MainPages.StoreGoods,
                    component: () => import("@/views/main/stores/goods/index.vue"),
                    meta: {
                        title: "Склады",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/good-registrations/:id?",
                    name: MainPages.StoreGoodRegistrations,
                    component: () => import("@/views/main/stores/good-registrations/index.vue"),
                    meta: {
                        title: "Оприходования",
                        active: MainPages.StoreGoods,
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/good-write-offs/:id?",
                    name: MainPages.StoreGoodWriteOffs,
                    component: () => import("@/views/main/stores/good-write-offs/index.vue"),
                    meta: {
                        title: "Списания",
                        active: MainPages.StoreGoods,
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/good-inventories/:id?",
                    name: MainPages.StoreGoodInventories,
                    component: () => import("@/views/main/stores/good-inventories/index.vue"),
                    meta: {
                        title: "Инвентаризации",
                        active: MainPages.StoreGoods,
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/clients/:id?",
                    name: "clients",
                    component: () => import("@/views/main/clients/clients.vue"),
                    meta: {
                        title: "Клиенты",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/salary/:id?",
                    name: "salary",
                    component: () => import("@/views/main/salary/salary.vue"),
                    meta: {
                        title: "Зарплата",
                        active: "accounts",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/reports/:id?",
                    name: "reports",
                    component: () => import("@/views/main/reports/reports.vue"),
                    meta: {
                        title: "Отчёты",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/settings/profile/:id?",
                    name: "settings-profile",
                    component: () => import("@/views/main/settings/settings-profile/settings-profile.vue"),
                    meta: {
                        title: "Настройки профиля",
                        active: "settings-profile",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/settings/company/:id?",
                    name: "settings-company",
                    component: () => import("@/views/main/settings/settings-company/settings-company.vue"),
                    meta: {
                        title: "Настройки компании",
                        active: "settings-company",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/settings/lists/:id?",
                    name: "settings-lists",
                    component: () => import("@/views/main/settings/settings-lists/settings-lists.vue"),
                    meta: {
                        title: "Настройки списков",
                        active: "settings-lists",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/settings/templates/:id?",
                    name: "settings-templates",
                    component: () => import("@/views/main/settings/settings-templates/settings-templates.vue"),
                    meta: {
                        title: "Настройки шаблонов",
                        active: "settings-templates",
                        auth: AuthType.Company,
                    },
                },
                {
                    path: "/settings/help/:id?",
                    name: "settings-help",
                    component: () => import("@/views/main/settings/settings-help/settings-help.vue"),
                    meta: {
                        title: "Справка",
                        active: "settings-help",
                        auth: AuthType.Company,
                    },
                },
            ],
        },

        // =============================================================================
        // UNAUTH PAGES
        // =============================================================================
        {
            path: "",
            component: () => import("@/layouts/full-page/index.vue"),
            children: [
                // {
                //     path: "/callback",
                //     name: "auth-callback",
                //     component: () => import("@/views/auth/Callback.vue"),
                //     meta: {
                //         auth: AuthType.Unauth,
                //     }
                // },
                {
                    path: "/login",
                    name: "login",
                    component: () => import("@/views/main/auth/login/index.vue"),
                    meta: {
                        title: "Вход",
                        auth: AuthType.Unauth,
                    },
                },
                {
                    path: "/registration",
                    name: "registration",
                    component: () => import("@/views/main/auth/registration/index.vue"),
                    meta: {
                        title: "Регистрация",
                        auth: AuthType.Unauth,
                    },
                },
                {
                    path: "/forgot-password",
                    name: "forgot-password",
                    component: () => import("@/views/main/auth/forgot-password/index.vue"),
                    meta: {
                        title: "Восстановление пароля",
                        auth: AuthType.Unauth,
                    },
                },
                {
                    path: "/reset-password/:id?",
                    name: "reset-password",
                    component: () => import("@/views/main/auth/reset-password/index.vue"),
                    meta: {
                        title: "Восстановление пароля",
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/confirm-email/:id?",
                    name: "confirm-email",
                    component: () => import("@/views/main/auth/confirm-email/index.vue"),
                    meta: {
                        title: "Подтверждение почты",
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/spy",
                    name: "spy",
                    component: () => import("@/views/main/auth/spy-login/index.vue"),
                    meta: {
                        title: "Вход от имени",
                        auth: AuthType.Unauth,
                    },
                },
                {
                    path: "/authorization/:action/:social",
                    name: "socials",
                    component: () => import("@/views/main/auth/socials/index.vue"),
                    meta: {
                        title: "Вход с помощью соцсети",
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/switch-company",
                    name: "switch-company",
                    component: () => import("@/views/main/auth/switch-company/index.vue"),
                    meta: {
                        title: "Выбор компании",
                        auth: AuthType.UserOnly,
                    },
                },

                // =============================================================================
                // ERROR PAGES etc
                // =============================================================================
                {
                    path: "/maintenance",
                    name: "page-maintenance",
                    component: () => import("@/views/errors/maintenance.vue"),
                    meta: {
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/error-401",
                    name: "page-error-401",
                    component: () => import("@/views/errors/error-401.vue"),
                    meta: {
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/error-404",
                    name: "page-error-404",
                    component: () => import("@/views/errors/error-404.vue"),
                    meta: {
                        title: "404",
                        auth: AuthType.Public,
                    },
                },
                {
                    path: "/error-500",
                    name: "page-error-500",
                    component: () => import("@/views/errors/error-500.vue"),
                    meta: {
                        title: "500",
                        auth: AuthType.Public,
                    },
                },
            ],
        },

        // =============================================================================
        // Redirect to 404 page, if no match found
        // =============================================================================
        {
            path: "*",
            redirect: "/error-404",
        },
    ],
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

router.beforeEach(async (to: any, from: any, next: any) => {
    if (to.meta?.auth === AuthType.Company && !router.app.$alt.system.token.hasCompanyToken()) {
        await router.push({ name: "login" }).catch(() => {});
        // TODO: russian text
        router.app.$alt.toast.error("Доступ запрещён.");
        return;
    }

    if (to.meta?.auth === AuthType.UserOnly && !router.app.$alt.system.token.hasUserOnlyToken()) {
        await router.push({ name: "login" }).catch(() => {});
        // TODO: russian text
        router.app.$alt.toast.error("Доступ запрещён.");
        return;
    }

    if (
        to.meta?.auth !== AuthType.Company &&
        to.meta?.auth !== AuthType.Public &&
        router.app.$alt.system.token.hasCompanyToken()
    ) {
        await router.push({ name: "home" }).catch(() => {});
        return;
    }

    if (
        to.meta?.auth !== AuthType.UserOnly &&
        to.meta?.auth !== AuthType.Public &&
        router.app.$alt.system.token.hasUserOnlyToken()
    ) {
        await router.push({ name: "switch-company" }).catch(() => {});
        return;
    }

    if (to.meta && to.meta.title) {
        document.title = to.meta.title + " | Alt SC";
    } else {
        document.title = "Alt SC";
    }

    return next();
});

export default router;
