import _Vue from "vue";

function installWidget(id: number, text: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const head = document.head || document.getElementsByTagName("head")[0];

        const script1 = document.createElement("script");
        script1.type = "text/javascript";
        script1.async = true;
        script1.src = "https://vk.com/js/api/openapi.js?168";
        head.appendChild(script1);

        script1.onload = () => {
            const body = document.body || document.getElementsByTagName("body")[0];

            const divId = "vk_community_messages";
            const inner = `VK.Widgets.CommunityMessages("${divId}", ${id}, { tooltipButtonText: "${text}" });`;

            const div = document.createElement("div");
            div.id = divId;
            div.style.visibility = "hidden";
            body.appendChild(div);

            const script2 = document.createElement("script");
            script2.type = "text/javascript";
            script2.async = true;
            script2.innerHTML = inner;
            body.appendChild(script2);

            script2.onload = resolve;
            script2.onerror = reject;
        };
    });
}

// <script type="text/javascript" src="https://vk.com/js/api/openapi.js?168"></script>
//
// <!— VK Widget —>
// <div id="vk_community_messages"></div>
// <script type="text/javascript">
//     VK.Widgets.CommunityMessages("vk_community_messages", 000000000, {tooltipButtonText: "Техподдержка"});
// </script>

export function VueVkWidget(Vue: typeof _Vue, options: { id: number; text: string }): void {
    if (document.readyState === "complete") {
        installWidget(options.id, options.text);
    } else {
        window.addEventListener("load", () => installWidget(options.id, options.text), false);
    }
}
