import { ISaleTypeUseCase, ISaleType } from "@lib";
import { ISaleTypeCreateDto, ISaleTypeUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class SaleTypeUseCase implements ISaleTypeUseCase {
    public async select(companyId: string): Promise<ISaleType[]> {
        try {
            const response = await Api.get<ISaleType[]>(`/companies/${companyId}/lists/saletypes`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ISaleType> {
        try {
            const response = await Api.get<ISaleType>(`/companies/${companyId}/lists/saletypes/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ISaleTypeCreateDto): Promise<ISaleType> {
        try {
            const response = await Api.post<ISaleType>(`/companies/${companyId}/lists/saletypes`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: ISaleTypeCreateDto[]): Promise<ISaleType[]> {
        try {
            const response = await Api.post<ISaleType[]>(`/companies/${companyId}/lists/saletypes/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: ISaleTypeUpdateDto): Promise<ISaleType> {
        try {
            const response = await Api.put<ISaleType>(`/companies/${companyId}/lists/saletypes/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/lists/saletypes/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
