import {
    ISaleUseCase,
    ISale,
    ISelectedData,
    ISaleCreateDto,
    ISaleDeleteManyDto,
    ISaleDeleteDto,
    ISalePaymentCreateDto,
    ISaleGoodDto,
    ISaleInfoChangeDto,
    IReadQuery,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class SaleUseCase implements ISaleUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<ISale>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<ISale>>(`/companies/${companyId}/sales`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ISale> {
        try {
            const response = await Api.get<ISale>(`/companies/${companyId}/sales/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(
        companyId: string,
        shopId: string,
        saleDto: ISaleCreateDto,
        goodsDto?: ISaleGoodDto[],
        paymentDto?: ISalePaymentCreateDto,
    ): Promise<ISale> {
        try {
            const dto = { shop: shopId, sale: saleDto, goods: goodsDto, payment: paymentDto };
            const response = await Api.post<ISale>(`/companies/${companyId}/sales`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeNumber(companyId: string, saleId: string, number: number): Promise<ISale> {
        try {
            const dto = { number };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/number`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeInfo(
        companyId: string,
        saleId: string,
        infoDto: ISaleInfoChangeDto,
        customDto: any,
    ): Promise<ISale> {
        try {
            const dto = { info: infoDto, custom: customDto };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/info`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeStage(companyId: string, saleId: string, stageId: string, comment: string): Promise<ISale> {
        try {
            const dto = { stage: stageId, comment };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/stage`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeClient(companyId: string, saleId: string, clientId: string): Promise<ISale> {
        try {
            const dto = { client: clientId };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/client`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeSeller(companyId: string, saleId: string, sellerId: string): Promise<ISale> {
        try {
            const dto = { seller: sellerId };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/seller`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeGoods(companyId: string, saleId: string, goodsDto: ISaleGoodDto[]): Promise<ISale> {
        try {
            const dto = { goods: goodsDto };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/goods`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async addComment(companyId: string, saleId: string, comment: string): Promise<ISale> {
        try {
            const dto = { comment };
            const response = await Api.post<ISale>(`/companies/${companyId}/sales/${saleId}/comments`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async complete(
        companyId: string,
        saleId: string,
        stageId: string,
        infoDto?: ISaleInfoChangeDto,
        customDto?: any,
        paymentDto?: ISalePaymentCreateDto,
    ): Promise<ISale> {
        try {
            const dto = { stage: stageId, info: infoDto, custom: customDto, payment: paymentDto };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/complete`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async reopen(companyId: string, saleId: string, stageId: string): Promise<ISale> {
        try {
            const dto = { stage: stageId };
            const response = await Api.patch<ISale>(`/companies/${companyId}/sales/${saleId}/reopen`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string, dto: ISaleDeleteDto): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/sales/${id}`, { data: dto });
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: ISaleDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/sales/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
