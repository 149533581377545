






import { Vue, Component, Watch } from "vue-property-decorator";
import themeConfig from "@/../themeConfig";

@Component
export default class App extends Vue {
    private vueAppClasses: string[] = [];

    private toggleClassInBody(className: string): void {
        if (className === "dark") {
            if (document.body.className.match("theme-semi-dark")) {
                document.body.classList.remove("theme-semi-dark");
            }
            document.body.classList.add("theme-dark");
        } else if (className === "semi-dark") {
            if (document.body.className.match("theme-dark")) {
                document.body.classList.remove("theme-dark");
            }
            document.body.classList.add("theme-semi-dark");
        } else {
            if (document.body.className.match("theme-dark")) {
                document.body.classList.remove("theme-dark");
            }
            if (document.body.className.match("theme-semi-dark")) {
                document.body.classList.remove("theme-semi-dark");
            }
        }
    }

    private setAppClasses(classesStr: string): void {
        this.vueAppClasses.push(classesStr);
    }

    private handleWindowResize(): void {
        this.$info.ui.windowWidth = window.innerWidth;

        // Set --vh property
        document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    }

    public mounted(): void {
        this.toggleClassInBody(themeConfig.theme);
        this.$info.ui.windowWidth = window.innerWidth;

        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    public async beforeCreate(): Promise<void> {
        // инициализируем контекст плагина, чтобы показывать сообщения и др.
        this.$alt.context = this;

        document.documentElement.setAttribute("dir", "ltr");
    }

    public async created(): Promise<void> {
        try {
            window.addEventListener("resize", this.handleWindowResize);

            // Auth0
            //await this.$auth.renewTokens();

            // for debug
            //await this.sleep(2000);

            // для авторизованного пользователя получаем данные
            if (this.$alt.system.token.hasCompanyToken()) {
                const result = await this.$alt.system.usecase.createAuthCompanyUseCase().loadInfo();
                this.$info.setAuthInfo(result);
                this.$settings.setUserInfo(result.user.user.id);
                if (result.company) {
                    this.$secure.grant(result.company.permissions);
                }
            } else if (this.$alt.system.token.hasUserOnlyToken()) {
                const result = await this.$alt.system.usecase.createAuthUserUseCase().loadInfo();
                this.$info.setAuthInfo(result);
                this.$settings.setUserInfo(result.user.user.id);
            }

            // // для админа получаем данные
            // if (this.$alt.system.token.hasCompanyToken()) {
            //     //
            // }
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось загрузить данные: ${e.message}`);
        }
    }

    private sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public destroyed(): void {
        window.removeEventListener("resize", this.handleWindowResize);
    }
}
