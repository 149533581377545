import {
    IAuthUserUseCase,
    ICompany,
    IAuthLoginResultDto,
    ICompanyCreateDto,
    IAuthRefreshResultDto,
    IAuthLoadInfoResultDto,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api, ApiAuth } from "../axios";

export class AuthUserUseCase implements IAuthUserUseCase {
    public async companies(): Promise<ICompany[]> {
        try {
            const response = await Api.get<ICompany[]>("/auth/user/companies");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createCompany(dto: ICompanyCreateDto): Promise<ICompany> {
        try {
            const response = await Api.post<ICompany>("/auth/user/companies", dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async leaveCompany(companyId: string): Promise<void> {
        try {
            await Api.delete<void>(`/auth/user/companies/${companyId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async loadInfo(): Promise<IAuthLoadInfoResultDto> {
        try {
            const response = await Api.get<IAuthLoadInfoResultDto>("/auth/user/info");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async login(companyId: string): Promise<IAuthLoginResultDto> {
        try {
            const response = await Api.post<IAuthLoginResultDto>("/auth/user/login", { companyId });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async logout(): Promise<void> {
        try {
            await ApiAuth.post<void>("/auth/user/logout");
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async refresh(): Promise<IAuthRefreshResultDto> {
        try {
            const response = await ApiAuth.post<IAuthRefreshResultDto>("/auth/user/refresh");
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
