/** Оператор фильтра. */
export enum TableFilterOperator {
    /** `==` */
    Equals = "eq",

    /** `!=` */
    NotEquals = "ne",

    /** `[].includes()` */
    In = "in",

    /** `>` */
    Gt = "gt",

    /** `>=` */
    Gte = "gte",

    /** `<` */
    Lt = "lt",

    /** `<=` */
    Lte = "lte",
}
