import { ITemplateLabelUseCase, ITemplateLabel, TemplateLabelType } from "@lib";
import { ITemplateLabelCreateDto, ITemplateLabelCreateFromTemplateDto } from "@lib";
import { ITemplateLabelUpdateDto, ITemplateLabelUpdateTemplateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class TemplateLabelUseCase implements ITemplateLabelUseCase {
    public async select(companyId: string, type?: TemplateLabelType): Promise<ITemplateLabel[]> {
        try {
            const params = { type };
            const response = await Api.get<ITemplateLabel[]>(`/companies/${companyId}/templates/labels`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<ITemplateLabel> {
        try {
            const response = await Api.get<ITemplateLabel>(`/companies/${companyId}/templates/labels/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, dto: ITemplateLabelCreateDto): Promise<ITemplateLabel> {
        try {
            const response = await Api.post<ITemplateLabel>(`/companies/${companyId}/templates/labels`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createMany(companyId: string, dtos: ITemplateLabelCreateDto[]): Promise<ITemplateLabel[]> {
        try {
            const response = await Api.post<ITemplateLabel[]>(`/companies/${companyId}/templates/labels/many`, dtos);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createFromTemplate(
        companyId: string,
        dto: ITemplateLabelCreateFromTemplateDto,
    ): Promise<ITemplateLabel> {
        try {
            const response = await Api.post<ITemplateLabel>(`/companies/${companyId}/templates/labels/template`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async createManyFromTemplate(
        companyId: string,
        dtos: ITemplateLabelCreateFromTemplateDto[],
    ): Promise<ITemplateLabel[]> {
        try {
            const response = await Api.post<ITemplateLabel[]>(
                `/companies/${companyId}/templates/labels/many/template`,
                dtos,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: ITemplateLabelUpdateDto): Promise<ITemplateLabel> {
        try {
            const response = await Api.put<ITemplateLabel>(`/companies/${companyId}/templates/labels/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async updateTemplate(
        companyId: string,
        id: string,
        dto: ITemplateLabelUpdateTemplateDto,
    ): Promise<ITemplateLabel> {
        try {
            const response = await Api.patch<ITemplateLabel>(
                `/companies/${companyId}/templates/labels/${id}/template`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async archive(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/templates/labels/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
