import { ISettingsUserUseCase, ISettingsUser, SettingsUserType } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export const TypeWebUI = "webui";

export class SettingsUserUseCase implements ISettingsUserUseCase {
    public async select(userId: string, type: SettingsUserType = TypeWebUI): Promise<ISettingsUser> {
        try {
            const response = await Api.get<ISettingsUser>(`/users/${userId}/settings/${type}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async set(
        userId: string,
        type: SettingsUserType = TypeWebUI,
        key: string,
        value: any,
    ): Promise<ISettingsUser> {
        try {
            const data = { key, value };
            const response = await Api.put<ISettingsUser>(`/users/${userId}/settings/${type}`, data);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
