import { Currency } from "@lib";
import { Gender } from "@/core/types";
import { CurrencyUtils } from "@/utils/types/currency.utils";

const WordsNull = "ноль";
const WordsSingleM = ["", "один", "два", "три", "четыре", "пять", "шесть", "семь", "восемь", "девять"];
const WordsSingleF = ["", "одна", "две", "три", "четыре", "пять", "шесть", "семь", "восемь", "девять"];
const WordsSingleN = ["", "одно", "два", "три", "четыре", "пять", "шесть", "семь", "восемь", "девять"];
const WordsTwenties = [
    "десять",
    "одиннадцать",
    "двенадцать",
    "тринадцать",
    "четырнадцать",
    "пятнадцать",
    "шестнадцать",
    "семнадцать",
    "восемнадцать",
    "девятнадцать",
];
const WordsDecades = [
    "",
    "десять",
    "двадцать",
    "тридцать",
    "сорок",
    "пятьдесят",
    "шестьдесят",
    "семьдесят",
    "восемьдесят",
    "девяносто",
];
const WordsHundreds = [
    "",
    "сто",
    "двести",
    "триста",
    "четыреста",
    "пятьсот",
    "шестьсот",
    "семьсот",
    "восемьсот",
    "девятьсот",
];

const MorphemesThousands = ["тысяча", "тысячи", "тысяч"];
const MorphemesMillions = ["миллион", "миллиона", "миллионов"];
const MorphemesBillions = ["миллиард", "милиарда", "миллиардов"];

export abstract class GrammarUtils {
    public static moneyInWords(number: number, currency: Currency = Currency.RUB, withKop: boolean = true): string {
        const numRub = Math.trunc(number);
        const numKop = Math.trunc((number * 100) % 100);

        const morph = CurrencyUtils.getMorphemes(currency);

        const wordsNumRub = this.numberInWords(numRub, morph.gender);
        const wordsRub = this.decline(numRub, morph.main);
        const wordsKop = this.decline(numKop, morph.fraction);

        return withKop ? `${wordsNumRub} ${wordsRub} ${numKop} ${wordsKop}` : `${wordsNumRub} ${wordsRub}`;
    }

    public static numberInWords(number: number, gender: Gender = Gender.Male): string {
        if (number === 0) {
            return WordsNull;
        }

        // морфемы для порядков (тысячи, миллионы...)
        const ordersMorphemes = [[""], MorphemesThousands, MorphemesMillions, MorphemesBillions];
        // род для порядков
        const ordersGender = [gender, Gender.Female, Gender.Male, Gender.Male];

        const result: string[] = [];
        const thousands = this.splitNumberByOrder(number, 3);
        for (let i = 0; i < thousands.length; ++i) {
            if (i > 0) {
                // тысяч, миллионов, миллиардов...
                result.push(this.decline(thousands[i], ordersMorphemes[i]));
            }

            const digits = this.splitNumberByOrder(thousands[i]);
            this.addSingleInWords(digits, ordersGender[i], result);
            this.addDecadesInWords(digits, result);
            this.addHundredsInWords(digits, result);
        }

        return result.reverse().join(" ");
    }

    private static addSingleInWords(digits: number[], gender: Gender, result: string[]): void {
        if (digits.length >= 1 && digits[0] !== 0) {
            if (gender === Gender.Male) {
                result.push(WordsSingleM[digits[0]]);
            } else if (gender === Gender.Female) {
                result.push(WordsSingleF[digits[0]]);
            } else if (gender === Gender.Neutral) {
                result.push(WordsSingleN[digits[0]]);
            }
        }
    }

    private static addDecadesInWords(digits: number[], result: string[]): void {
        if (digits.length >= 2) {
            if (digits[1] > 1) {
                // 20 и больше
                result.push(WordsDecades[digits[1]]);
            } else if (digits[1] === 1 && digits[0] === 0) {
                // 10
                result.push(WordsTwenties[digits[0]]);
            } else if (digits[1] !== 0) {
                // от 11 до 19
                result.pop(); //
                result.push(WordsTwenties[digits[0]]);
            }
        }
    }

    private static addHundredsInWords(digits: number[], result: string[]): void {
        if (digits.length === 3 && digits[2] !== 0) {
            result.push(WordsHundreds[digits[2]]);
        }
    }

    // private static splitNumberByOrder2(number: number, order = 10): number[] {
    //     let currentOrder = 1;
    //     let value = 0;
    //     const result: number[] = [];
    //     do {
    //         value = Math.trunc(number / currentOrder % order);
    //         result.push(value);
    //         currentOrder *= order;
    //     } while (number > currentOrder);
    //     return result;
    // }

    private static splitNumberByOrder(number: number, count = 1): number[] {
        const result: number[] = [];

        let numString: string = number.toString();
        // добавляем нули в начало
        if (numString.length % count !== 0) {
            numString = "0".repeat(count - (numString.length % count)) + numString;
        }

        for (let i = numString.length - count; i >= 0; i -= count) {
            result.push(parseInt(numString.substr(i, count)));
        }
        return result;
    }

    /** Выбрать форму слова в зависимости от количества. */
    public static decline(number: number, morphemes: string[]): string {
        number = Math.abs(number) % 100;

        if (number > 10 && number < 20) {
            return morphemes[2];
        }

        number = number % 10;
        if (number >= 2 && number <= 4) {
            return morphemes[1];
        }

        if (number === 1) {
            return morphemes[0];
        }

        return morphemes[2];
    }
}
