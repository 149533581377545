import _Vue from "vue";

function installWidget(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
        const head = document.head || document.getElementsByTagName("head")[0];

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(${id}, "init", {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
});`;
        head.appendChild(script);

        const body = document.body || document.getElementsByTagName("body")[0];

        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
<div><img src="https://mc.yandex.ru/watch/${id}" style="position:absolute; left:-9999px;" alt="" /></div>`;
        body.appendChild(noscript);

        resolve(true);
    });
}

export function VueYandexMetrikaWidget(Vue: typeof _Vue, options: { id: number }): void {
    if (document.readyState === "complete") {
        installWidget(options.id);
    } else {
        window.addEventListener("load", () => installWidget(options.id), false);
    }
}
