import { IOrderPaymentUseCase, IOrder, IOrderPaymentCreateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class OrderPaymentUseCase implements IOrderPaymentUseCase {
    public async create(
        companyId: string,
        officeId: string,
        orderId: string,
        dto: IOrderPaymentCreateDto,
    ): Promise<IOrder> {
        try {
            const response = await Api.post<IOrder>(
                `/companies/${companyId}/offices/${officeId}/orders/${orderId}/payments`,
                dto,
            );

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, officeId: string, orderId: string, paymentId: string): Promise<IOrder> {
        try {
            const response = await Api.delete<IOrder>(
                `/companies/${companyId}/offices/${officeId}/orders/${orderId}/payments/${paymentId}`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
