/** Конкретные права доступа. */
export enum PermissionRight {
    /** Все права. */
    Admin = "admin",

    /** Просмотр. */
    Read = "read",
    /** Создание. */
    Create = "create",
    /** Обновление. */
    Update = "update",
    /** Удаление. */
    Delete = "delete",

    /** Просмотр заявок. */
    OrdersRead = "orders-read",
    /** Создание заявок. */
    OrdersCreate = "orders-create",
    /** Обновление заявок. */
    OrdersUpdate = "orders-update",
    /** Удаление заявок. */
    OrdersDelete = "orders-delete",

    /** Просмотр продаж. */
    SalesRead = "sales-read",
    /** Создание продаж. */
    SalesCreate = "sales-create",
    /** Обновление продаж. */
    SalesUpdate = "sales-update",
    /** Удаление продаж. */
    SalesDelete = "sales-delete",

    /** Просмотр задач. */
    TasksRead = "tasks-read",
    /** Создание задач. */
    TasksCreate = "tasks-create",
    /** Обновление задач. */
    TasksUpdate = "tasks-update",
    /** Удаление задач. */
    TasksDelete = "tasks-delete",

    /** Просмотр товаров. */
    GoodsRead = "goods-read",
    /** Создание товаров. */
    GoodsCreate = "goods-create",
    /** Обновление товаров. */
    GoodsUpdate = "goods-update",
    /** Использование товаров в качестве материалов в заявках. */
    GoodsAsOrderMaterials = "goods-as-order-materials",
    /** Продажа товаров. */
    GoodsSale = "goods-sale",
    /** Удаление товаров. */
    GoodsDelete = "goods-delete",

    /** Просмотр транзакций. */
    TransactionsRead = "transactions-read",
    /** Создание транзакций. */
    TransactionsCreate = "transactions-create",
    // /** Обновление транзакций. */
    // TransactionsUpdate = "transactions-update",
    /** Удаление транзакций. */
    TransactionsDelete = "transactions-delete",

    /** Просмотр выплат. */
    SalaryRead = "salary-read",
    /** Создание выплат. */
    SalaryCreate = "salary-create",
    // /** Обновление выплат. */
    // SalaryUpdate = "salary-update",
    /** Удаление выплат. */
    SalaryDelete = "salary-delete",

    /** Изменение модулей. */
    ModulesUpdate = "modules-update",
}
