/** Пара строк. */
export type StringPair = [string, string];

/** Пол. */
export enum Gender {
    Male = "male",
    Female = "female",
    Neutral = "neutral",
}

/** Период. */
export enum Period {
    Today = "today",
    Yesterday = "yesterday",
    Week = "week",
    Month = "month",
    Custom = "custom",
}

export interface ISelectOption<T = string> {
    id: T;
    name: string;
    description?: string;
}

export interface ISelectOptionData<T = any> {
    id: string;
    getName: (t: T) => string;
    getDescription?: (t: T) => string;
    data?: T;
}
