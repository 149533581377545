import { IProjectModuleUseCase, IProjectModule } from "@lib";
import { IProjectModuleCreateDto, IProjectModuleUpdateDto } from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";

export class ProjectModuleUseCase implements IProjectModuleUseCase {
    public async select(companyId: string, projectId: string): Promise<IProjectModule[]> {
        try {
            const response = await Api.get<IProjectModule[]>(`/companies/${companyId}/projects/${projectId}/modules`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, projectId: string, pmoduleId: string): Promise<IProjectModule> {
        try {
            const response = await Api.get<IProjectModule>(
                `/companies/${companyId}/projects/${projectId}/modules/${pmoduleId}`,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, projectId: string, dto: IProjectModuleCreateDto): Promise<IProjectModule> {
        try {
            const response = await Api.post<IProjectModule>(
                `/companies/${companyId}/projects/${projectId}/modules`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(
        companyId: string,
        projectId: string,
        pmoduleId: string,
        dto: IProjectModuleUpdateDto,
    ): Promise<IProjectModule> {
        try {
            const response = await Api.put<IProjectModule>(
                `/companies/${companyId}/projects/${projectId}/modules/${pmoduleId}`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, projectId: string, pmoduleId: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/projects/${projectId}/modules/${pmoduleId}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
