import { configuration } from "@/configuration";
import { AltLoader } from "./alt-loader";
import { AltMessage } from "./alt-message";
import { AltToast } from "./alt-toast";
import { AltSystem } from "./alt-system";
import { ObjectUtils } from "../object.utils";
import { Formatter } from "../formatter";

export class AltPlugin {
    private _context: Vue | undefined;
    private readonly _system = new AltSystem();
    private readonly _loader = new AltLoader();
    private readonly _toast = new AltToast();
    private readonly _message = new AltMessage();

    /** Текущий контекст. */
    public set context(context: Vue) {
        this._context = context;
        this._toast.context = context;
        this._message.context = context;
    }

    /** Конфигурация. */
    public get config(): typeof configuration {
        return configuration;
    }

    /** Глобальные объекты системы. */
    public get system(): AltSystem {
        return this._system;
    }

    /** Крутилка загрузки. */
    public get loader(): AltLoader {
        return this._loader;
    }

    /** Всплывающее сообщение. */
    public get toast(): AltToast {
        return this._toast;
    }

    /** Сообщение. */
    public get message(): AltMessage {
        return this._message;
    }

    /** Форматирование данных. */
    public get formatter(): typeof Formatter {
        return Formatter;
    }

    /** Клонировать объект. */
    public clone<T = any>(value: T): T {
        return ObjectUtils.clone(value);
    }

    /** Сравнить массивы объектов по значениям. */
    public compareArrays<T = any>(array1: T[], array2: T[], getId: (el: T) => any): boolean {
        return ObjectUtils.compareArrays(array1, array2, getId);
    }

    /** Ожидание в миллисекундах. */
    public sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /** Проверка, включена ли скрытая функциональность. */
    public isFeatureEnabled(name: string): boolean {
        return !!localStorage.getItem(name);
    }

    /** Получить значение из локального хранилища. */
    public getFromLocalStorage(key: string): string | null {
        return localStorage.getItem(key);
    }

    /** Сохранить значение в локальное хранилище. Если значение `undefined` - удалить. */
    public setToLocalStorage(key: string, value?: string): void {
        if (!value) {
            this.removeFromLocalStorage(key);
            return;
        }

        localStorage.setItem(key, value);
    }

    /** Удалить значение из локального хранилища. */
    public removeFromLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }

    /** Есть ли параметр запроса. */
    public queryExits(param: string): boolean {
        return this._context?.$route.query[param] !== undefined;
    }

    /** Есть ли непустой параметр запроса. */
    public queryNotEmpty(param: string): boolean {
        return this._context?.$route.query[param] !== undefined && this._context.$route.query[param].length > 0;
    }

    /** Получить значение первого параметра запроса. */
    public queryGetFirst(param: string): string | null {
        if (!this._context || !this.queryNotEmpty(param)) {
            return null;
        }

        const value =
            this._context.$route.query[param] instanceof Array
                ? this._context.$route.query[param][0]
                : this._context.$route.query[param];

        return value as string;
    }

    // /** Добавить параметр к запросу. */
    // public queryAdd(name: string, value: any): void {
    //     const query: any = JSON.parse(JSON.stringify(this._context?.$route.query));
    //     query[name] = value;
    //     this._context?.$router.replace({ query }).catch(() => {});
    // }

    // /** Удалить параметр из запроса. */
    // public queryRemove(name: string): void {
    //     if (this._context?.$route.query[name]) {
    //         const query: any = JSON.parse(JSON.stringify(this._context?.$route.query));
    //         delete query[name];
    //         this._context?.$router.replace({ query }).catch(() => {});
    //     }
    // }

    /** Долгая операция, обёрнутая в крутилки. */
    public async longOperation(action: () => Promise<void>, successMessage?: string): Promise<boolean> {
        return this.longOperationBool(async () => {
            await action();
            return true;
        }, successMessage);
    }

    /** Долгая операция, обёрнутая в крутилки. */
    public async longOperationBool(predicate: () => Promise<boolean>, successMessage?: string): Promise<boolean> {
        try {
            this.loader.show();

            const result = await predicate();

            if (result && successMessage) {
                this.toast.success(successMessage);
            }

            return result;
        } catch (e: any) {
            this.toast.error(e.message);
            return false;
        } finally {
            this.loader.hide();
        }
    }

    /** Долгая операция, обёрнутая в крутилки. */
    public async longOperationFunc<T>(func: () => Promise<T>, successMessage?: string): Promise<T | null> {
        try {
            this.loader.show();

            const result = await func();

            if (result && successMessage) {
                this.toast.success(successMessage);
            }

            return result;
        } catch (e: any) {
            this.toast.error(e.message);
            return null;
        } finally {
            this.loader.hide();
        }
    }
}
