export abstract class ObjectUtils {
    /** Клонировать объект. */
    public static clone<T = any>(value: T): T {
        if (!value) {
            return value;
        }

        if (Array.isArray(value)) {
            return value.map(v => this.clone(v)) as any as T;
        }

        const clone = JSON.parse(JSON.stringify(value));

        if (typeof value === "object") {
            const obj = value as any;
            for (const field of Object.keys(obj)) {
                if (typeof obj[field] === "function") {
                    clone[field] = obj[field];
                }
            }

            clone.__proto__ = obj.__proto__;
        }

        return clone;
    }

    /**
     * Преобразовать сложный объект к "плоскому" виду. Например,
     * ```
     * obj = {
     *     a: 111,
     *     b: "aaa",
     *     c: {
     *         d: 222,
     *         e: true,
     *         f: {
     *             g: "bbb",
     *         },
     *     },
     * };
     * ```
     *
     * будет преобразован к виду:
     *
     * ```
     * result = {
     *     "a": 111,
     *     "b": "aaa",
     *     "c.d": 222,
     *     "c.e": true,
     *     "c.f.g": "bbb",
     * };
     * ```
     */
    public static flatten(obj: Record<string, any>): Record<string, any> {
        const result: Record<string, any> = {};

        for (const key in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, key)) {
                continue;
            }

            if (typeof obj[key] === "object" && obj[key] !== null) {
                const flatObject = ObjectUtils.flatten(obj[key]);

                for (const x in flatObject) {
                    if (!Object.prototype.hasOwnProperty.call(flatObject, x)) {
                        continue;
                    }

                    result[key + "." + x] = flatObject[x];
                }
            } else {
                result[key] = obj[key];
            }
        }

        return result;
    }

    /** Сравнить два массива объектов по значениям. */
    public static compareArrays<T = any>(array1: T[], array2: T[], getId: (el: T) => any): boolean {
        if (array1 === array2) {
            return true;
        }

        if (array1.length !== array2.length) {
            return false;
        }

        const set = new Set();

        for (const el1 of array1) {
            set.add(getId(el1));
        }

        for (const el2 of array2) {
            if (!set.has(getId(el2))) {
                return false;
            }
        }

        return true;
    }

    /** Поднять элемент массива. */
    public static upArrayItem<T = any>(array: T[], predicate: (item: T) => boolean): void {
        const index = array.findIndex(predicate);

        if (index > 0) {
            const items = array.splice(index, 1);
            array.splice(index - 1, 0, ...items);
        }
    }

    /** Опустить элемент массива. */
    public static downArrayItem<T = any>(array: T[], predicate: (item: T) => boolean): void {
        const index = array.findIndex(predicate);

        if (index < array.length - 1) {
            const items = array.splice(index, 1);
            array.splice(index + 1, 0, ...items);
        }
    }

    /** Удалить элемент массива. */
    public static deleteArrayItem<T = any>(array: T[], predicate: (item: T) => boolean): void {
        const index = array.findIndex(predicate);

        if (index >= 0) {
            array.splice(index, 1);
        }
    }

    /**
     * Simple object check.
     * @see https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
     * @param item
     * @returns {boolean}
     */
    public static isObject(item: any): boolean {
        return item && typeof item === "object" && !Array.isArray(item);
    }

    /**
     * Deep merge two objects.
     * @see https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
     * @param target
     * @param ...sources
     */
    public static mergeDeep(target: any, ...sources: any[]): any {
        if (!sources.length) {
            return target;
        }
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, { [key]: {} });
                    }
                    this.mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return this.mergeDeep(target, ...sources);
    }
}
