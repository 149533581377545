/** Тип разрешений. */
export enum PermissionType {
    /** Администратор компании. */
    Admin = "admin",

    /** Разрешения на общие разделы: клиенты, настройки и др. */
    Common = "common",

    /** Разрешения на филиалы. */
    Offices = "offices",

    /** Разрешения на магазины. */
    Shops = "shops",

    /** Разрешения на проекты. */
    Projects = "projects",

    /** Разрешения на склады. */
    Stores = "stores",

    /** Разрешения на счета. */
    Accounts = "accounts",

    /** Разрешения на сотрудников. */
    Employees = "employees",
}
