import {
    IOrderUseCase,
    IOrder,
    ISelectedData,
    IReadQuery,
    IOrderDeleteManyDto,
    IOrderCreateDto,
    IClientCreateDto,
    IOrderCreatePaymentDto,
    IOrderInfoChangeDto,
    IPluginNotificationSendDto,
    IOrderSendNotificationResultDto,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class OrderUseCase implements IOrderUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<IOrder>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IOrder>>(`/companies/${companyId}/orders`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IOrder> {
        try {
            const response = await Api.get<IOrder>(`/companies/${companyId}/orders/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(
        companyId: string,
        orderDto: IOrderCreateDto,
        clientDto?: IClientCreateDto,
        paymentDto?: IOrderCreatePaymentDto,
    ): Promise<IOrder> {
        try {
            const dto = { order: orderDto, client: clientDto, payment: paymentDto };
            const response = await Api.post<IOrder>(`/companies/${companyId}/orders`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeNumber(companyId: string, orderId: string, number: number): Promise<IOrder> {
        try {
            const dto = { number };
            const response = await Api.patch<IOrder>(`/companies/${companyId}/orders/${orderId}/number`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeInfo(
        companyId: string,
        orderId: string,
        infoDto: IOrderInfoChangeDto,
        customDto: any,
    ): Promise<IOrder> {
        try {
            const dto = { info: infoDto, custom: customDto };
            const response = await Api.patch<IOrder>(`/companies/${companyId}/orders/${orderId}/info`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeStage(companyId: string, orderId: string, stageId: string, comment: string): Promise<IOrder> {
        try {
            const dto = { stage: stageId, comment };
            const response = await Api.patch<IOrder>(`/companies/${companyId}/orders/${orderId}/stage`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeClient(companyId: string, orderId: string, clientId: string): Promise<IOrder> {
        try {
            const dto = { client: clientId };
            const response = await Api.patch<IOrder>(`/companies/${companyId}/orders/${orderId}/client`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeManager(companyId: string, orderId: string, managerId: string): Promise<IOrder> {
        try {
            const dto = { manager: managerId };
            const response = await Api.patch<IOrder>(`/companies/${companyId}/orders/${orderId}/manager`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async changeProduct(companyId: string, orderId: string, productId: string, dto: any): Promise<IOrder> {
        try {
            const response = await Api.patch<IOrder>(
                `/companies/${companyId}/orders/${orderId}/products/${productId}`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async addComment(companyId: string, orderId: string, comment: string): Promise<IOrder> {
        try {
            const dto = { comment };
            const response = await Api.post<IOrder>(`/companies/${companyId}/orders/${orderId}/comments`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async sendNotification(
        companyId: string,
        orderId: string,
        bindingId: string,
        dto: IPluginNotificationSendDto,
    ): Promise<IOrderSendNotificationResultDto> {
        try {
            const response = await Api.post<IOrderSendNotificationResultDto>(
                `/companies/${companyId}/orders/${orderId}/notifications/${bindingId}`,
                dto,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async complete(
        companyId: string,
        orderId: string,
        stageId: string,
        infoDto?: IOrderInfoChangeDto,
        customDto?: any,
        paymentDto?: IOrderCreatePaymentDto,
    ): Promise<IOrder> {
        try {
            const dto = {
                stage: stageId,
                info: infoDto,
                custom: customDto,
                payment: paymentDto,
            };
            const response = await Api.post<IOrder>(`/companies/${companyId}/orders/${orderId}/complete`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async reopen(companyId: string, orderId: string, stageId: string): Promise<IOrder> {
        try {
            const dto = { stage: stageId };
            const response = await Api.post<IOrder>(`/companies/${companyId}/orders/${orderId}/reopen`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/orders/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: IOrderDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/orders/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
