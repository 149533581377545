export default {
    admin: {
        analytics: {
            title: "Analytics",
        },

        companies: {
            title: "Companies",
        },

        users: {
            title: "Users",
        },

        tracking: {
            title: "Tracking",
        },
    },

    main: {
        dashboard: {
            title: "Dashboard",
        },

        orders: {
            title: "Orders",
        },

        sales: {
            title: "Shop",
        },

        tasks: {
            title: "Tasks",
        },

        accounts: {
            title: "Accounts",
        },

        stores: {
            title: "Stores",
        },

        clients: {
            title: "Clients",
        },

        reports: {
            title: "Reports",
        },

        tracking: {
            title: "Tracking",
        },

        settings: {
            title: "Settings",

            profile: {
                title: "Profile",

                profile: "Profile",
                security: "Security",
                socials: "Social",
            },

            company: {
                title: "Company",

                company: "Company",
                payments: "Payments",
                plugins: "Integrations",
                employees: "Employees",
                offices: "Offices",
                shops: "Shops",
                projects: "Projects",
                accounts: "Accounts",
                stores: "Stores",
            },

            lists: {
                title: "Lists",

                ordertypes: "Order types",
                orderstages: "Order stages",
                salestages: "Sale stages",
                taskstages: "Task stages",
                producttypes: "Product types",
                categories: "Good categories",
                transactionitems: "Transaction items",
                clientsources: "Client sources",
                works: "Works",
                suppliers: "Suppliers",
            },

            templates: {
                title: "Templates",

                documents: "Documents",
                sms: "Sms",
                labels: "Labels",
            },

            help: {
                title: "Help",
            },
        },

        features: {
            barcodes: {
                typenotspecified: "Type is not specified",
                codenotspecified: "Barcode is not specified",
                apinotspecified: "System error",
                invalidsyntax: "Invalid barcode",
                alreadyexists: "Barcode is alredy registered",
                unknownerror: "Unknown error",
            },
        },
    },

    common: {
        ui: {
            button: {
                login: "Login",
                back: "Back",
            },
        },
    },

    errors: {
        error404: "Page Not Found",
        error500: "Internal Server Error",
        notauthorized: "Not Authorized",
        maintenance: "Under Maintenance!",
    },
};
