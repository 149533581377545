import { Diagnostics } from "@/utils/diagnostics";
import { ApiError } from ".";
import { ru } from "./api-error-messages";

export class DataLoadException extends Error {
    public code?: ApiError;

    public constructor(error?: any) {
        const message = DataLoadException.getMessage(error);
        super(message);
        this.code = error.response?.data?.code;
    }

    private static getMessage(error?: any): string {
        if (error.response?.data?.code) {
            return DataLoadException.getMessageByCode(error.response.data);
        }

        if (error.response?.data?.message) {
            return error.response.data.message;
        }

        if (error.request && !error.response) {
            return "Нет соединения с сервером.";
        }

        if (error.message) {
            return error.message;
        }

        if (typeof error === "string") {
            return error;
        }

        return DataLoadException.getMessageByCode(ApiError.UNKNOWN_ERROR);
    }

    private static getMessageByCode(error: any): string {
        Diagnostics.LogWarning(`Error ${error.code}: ${error.message ?? "Неизвестная ошибка."}`);
        return ru[error.code] ?? error.message ?? "Неизвестная ошибка.";
    }
}
