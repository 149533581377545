import {
    IAuthCompanyUseCase,
    IAuthSocialsUseCase,
    IAuthUserUseCase,
    IUseCaseFactory,
    IUserUseCase,
    ICompanyUseCase,
    ICompanyFeatureBarcodesUseCase,
    ICompanyPaymentUseCase,
    ICompanyPricingUseCase,
    IEmployeeUseCase,
    IPartnerUseCase,
    ISalaryUseCase,
    IClientUseCase,
    IOfficeUseCase,
    IShopUseCase,
    IProjectUseCase,
    IProjectModuleUseCase,
    IAccountUseCase,
    IStoreUseCase,
    IOrderUseCase,
    IOrderWorkUseCase,
    IOrderMaterialUseCase,
    IOrderTaskUseCase,
    IOrderPaymentUseCase,
    ISaleUseCase,
    ISalePaymentUseCase,
    ITransactionUseCase,
    IGoodUseCase,
    IGoodRegistrationUseCase,
    IGoodWriteOffUseCase,
    IGoodInventoryUseCase,
    IGoodInventoryItemUseCase,
    IOrderTypeUseCase,
    IOrderStageUseCase,
    ISaleStageUseCase,
    ISaleTypeUseCase,
    ITaskStageUseCase,
    ITaskUseCase,
    IProductTypeUseCase,
    ITransactionItemUseCase,
    IClientSourceUseCase,
    ITemplateDocumentUseCase,
    ITemplateLabelUseCase,
    ITemplateNotificationUseCase,
    IWorkUseCase,
    IGoodCategoryUseCase,
    ISupplierUseCase,
    IModuleUseCase,
    IPluginCardUseCase,
    IPluginBindingUseCase,
    IPluginUseCase,
    ISettingsFormUseCase,
    ISettingsTableUseCase,
    ISettingsUserUseCase,
    IReportUseCase,
    IAdminAuthUseCase,
    IAdminCompanyUseCase,
    IAdminUserUseCase,
    IAdminAnalyticsUseCase,
    ITrackingUseCase,
} from "@lib";
import { AuthCompanyUseCase } from "./auth/auth-company.usecase";
import { AuthSocialsUseCase } from "./auth/auth-socials.usecase";
import { AuthUserUseCase } from "./auth/auth-user.usecase";
import { UserUseCase } from "./user/user.usecase";
import { CompanyUseCase } from "./company/company.usecase";
import { CompanyFeatureBarcodesUseCase } from "./company/company-feature-barcodes.usecase";
import { CompanyPaymentUseCase } from "./company/company-payment.usecase";
import { CompanyPricingUseCase } from "./company/company-pricing.usecase";
import { EmployeeUseCase } from "./company/employee.usecase";
import { PartnerUseCase } from "./company/partner.usecase";
import { SalaryUseCase } from "./company/salary.usecase";
import { ClientUseCase } from "./client/client.usecase";
import { OfficeUseCase } from "./office/office.usecase";
import { ShopUseCase } from "./shop/shop.usecase";
import { AccountUseCase } from "./account/account.usecase";
import { StoreUseCase } from "./store/store.usecase";
import { OrderUseCase } from "./office/order.usecase";
import { OrderWorkUseCase } from "./office/order-work.usecase";
import { OrderMaterialUseCase } from "./office/order-material.usecase";
import { OrderTaskUseCase } from "./office/order-task.usecase";
import { OrderPaymentUseCase } from "./office/order-payment.usecase";
import { SaleUseCase } from "./shop/sale.usecase";
import { SalePaymentUseCase } from "./shop/sale-payment.usecase";
import { TaskUseCase } from "./project/task.usecase";
import { TransactionUseCase } from "./account/transaction.usecase";
import { GoodUseCase } from "./store/good.usecase";
import { GoodRegistrationUseCase } from "./store/good-registration.usecase";
import { GoodWriteOffUseCase } from "./store/good-write-off.usecase";
import { GoodInventoryUseCase } from "./store/good-inventory.usecase";
import { GoodInventoryItemUseCase } from "./store/good-inventory-item.usecase";
import { OrderTypeUseCase } from "./list/order-type.usecase";
import { OrderStageUseCase } from "./list/order-stage.usecase";
import { SaleStageUseCase } from "./list/sale-stage.usecase";
import { SaleTypeUseCase } from "./list/sale-type.usecase";
import { TaskStageUseCase } from "./list/task-stage.usecase";
import { ProjectUseCase } from "./project/project.usecase";
import { ProjectModuleUseCase } from "./project/project-module.usecase";
import { ProductTypeUseCase } from "./list/product-type.usecase";
import { GoodCategoryUseCase } from "./store/good-category.usecase";
import { SupplierUseCase } from "./list/supplier.usecase";
import { TransactionItemUseCase } from "./list/transaction-item.usecase";
import { ClientSourceUseCase } from "./list/client-source.usecase";
import { WorkUseCase } from "./list/work.usecase";
import { TemplateDocumentUseCase } from "./template/template-document.usecase";
import { TemplateLabelUseCase } from "./template/template-label.usecase";
import { TemplateNotificationUseCase } from "./template/template-notification.usecase";
import { ModuleUseCase } from "./project/module.usecase";
import { PluginCardUseCase } from "./plugin/plugin-card.usecase";
import { PluginBindingUseCase } from "./plugin/plugin-binding.service";
import { PluginUseCase } from "./plugin/plugin.usecase";
import { SettingsFormUseCase } from "./settings/settings-form.usecase";
import { SettingsTableUseCase } from "./settings/settings-table.usecase";
import { SettingsUserUseCase } from "./settings/settings-user.usecase";
import { ReportUseCase } from "./report/report.usecase";
import { TrackingUseCase } from "./public/tracking.usecase";
import { AdminAuthUseCase } from "./admin/admin-auth.usecase";
import { AdminCompanyUseCase } from "./admin/admin-company.usecase";
import { AdminAnalyticsUseCase } from "./admin/admin-analytics.usecase";

export class UseCaseFactory implements IUseCaseFactory {
    public createAuthCompanyUseCase(): IAuthCompanyUseCase {
        return new AuthCompanyUseCase();
    }

    public createAuthSocialsUseCase(): IAuthSocialsUseCase {
        return new AuthSocialsUseCase();
    }

    public createAuthUserUseCase(): IAuthUserUseCase {
        return new AuthUserUseCase();
    }

    public createUserUseCase(): IUserUseCase {
        return new UserUseCase();
    }

    public createCompanyUseCase(): ICompanyUseCase {
        return new CompanyUseCase();
    }

    public createCompanyFeatureBarcodesUseCase(): ICompanyFeatureBarcodesUseCase {
        return new CompanyFeatureBarcodesUseCase();
    }

    public createCompanyPaymentUseCase(): ICompanyPaymentUseCase {
        return new CompanyPaymentUseCase();
    }

    public createCompanyPricingUseCase(): ICompanyPricingUseCase {
        return new CompanyPricingUseCase();
    }

    public createEmployeeUseCase(): IEmployeeUseCase {
        return new EmployeeUseCase();
    }

    public createPartnerUseCase(): IPartnerUseCase {
        return new PartnerUseCase();
    }

    public createSalaryUseCase(): ISalaryUseCase {
        return new SalaryUseCase();
    }

    //

    public createClientUseCase(): IClientUseCase {
        return new ClientUseCase();
    }

    public createOfficeUseCase(): IOfficeUseCase {
        return new OfficeUseCase();
    }

    public createShopUseCase(): IShopUseCase {
        return new ShopUseCase();
    }

    public createProjectUseCase(): IProjectUseCase {
        return new ProjectUseCase();
    }

    public createProjectModuleUseCase(): IProjectModuleUseCase {
        return new ProjectModuleUseCase();
    }

    public createAccountUseCase(): IAccountUseCase {
        return new AccountUseCase();
    }

    public createStoreUseCase(): IStoreUseCase {
        return new StoreUseCase();
    }

    //

    public createOrderUseCase(): IOrderUseCase {
        return new OrderUseCase();
    }

    public createOrderWorkUseCase(): IOrderWorkUseCase {
        return new OrderWorkUseCase();
    }

    public createOrderMaterialUseCase(): IOrderMaterialUseCase {
        return new OrderMaterialUseCase();
    }

    public createOrderTaskUseCase(): IOrderTaskUseCase {
        return new OrderTaskUseCase();
    }

    public createOrderPaymentUseCase(): IOrderPaymentUseCase {
        return new OrderPaymentUseCase();
    }

    public createSaleUseCase(): ISaleUseCase {
        return new SaleUseCase();
    }

    public createSalePaymentUseCase(): ISalePaymentUseCase {
        return new SalePaymentUseCase();
    }

    public createTaskUseCase(): ITaskUseCase {
        return new TaskUseCase();
    }

    public createTransactionUseCase(): ITransactionUseCase {
        return new TransactionUseCase();
    }

    public createGoodUseCase(): IGoodUseCase {
        return new GoodUseCase();
    }

    public createGoodRegistrationUseCase(): IGoodRegistrationUseCase {
        return new GoodRegistrationUseCase();
    }

    public createGoodWriteOffUseCase(): IGoodWriteOffUseCase {
        return new GoodWriteOffUseCase();
    }

    public createGoodInventoryUseCase(): IGoodInventoryUseCase {
        return new GoodInventoryUseCase();
    }

    public createGoodInventoryItemUseCase(): IGoodInventoryItemUseCase {
        return new GoodInventoryItemUseCase();
    }

    //

    public createOrderTypeUseCase(): IOrderTypeUseCase {
        return new OrderTypeUseCase();
    }

    public createOrderStageUseCase(): IOrderStageUseCase {
        return new OrderStageUseCase();
    }

    public createSaleStageUseCase(): ISaleStageUseCase {
        return new SaleStageUseCase();
    }

    public createSaleTypeUseCase(): ISaleTypeUseCase {
        return new SaleTypeUseCase();
    }

    public createTaskStageUseCase(): ITaskStageUseCase {
        return new TaskStageUseCase();
    }

    public createProductTypeUseCase(): IProductTypeUseCase {
        return new ProductTypeUseCase();
    }

    public createTransactionItemUseCase(): ITransactionItemUseCase {
        return new TransactionItemUseCase();
    }

    public createClientSourceUseCase(): IClientSourceUseCase {
        return new ClientSourceUseCase();
    }

    public createWorkUseCase(): IWorkUseCase {
        return new WorkUseCase();
    }

    public createGoodCategoryUseCase(): IGoodCategoryUseCase {
        return new GoodCategoryUseCase();
    }

    public createSupplierUseCase(): ISupplierUseCase {
        return new SupplierUseCase();
    }

    //

    public createTemplateDocumentUseCase(): ITemplateDocumentUseCase {
        return new TemplateDocumentUseCase();
    }

    public createTemplateLabelUseCase(): ITemplateLabelUseCase {
        return new TemplateLabelUseCase();
    }

    public createTemplateNotificationUseCase(): ITemplateNotificationUseCase {
        return new TemplateNotificationUseCase();
    }

    //

    public createModuleUseCase(): IModuleUseCase {
        return new ModuleUseCase();
    }

    //

    public createPluginCardUseCase(): IPluginCardUseCase {
        return new PluginCardUseCase();
    }

    public createPluginBindingUseCase(): IPluginBindingUseCase {
        return new PluginBindingUseCase();
    }

    public createPluginUseCase(): IPluginUseCase {
        return new PluginUseCase();
    }

    //

    public createSettingsFormUseCase(): ISettingsFormUseCase {
        return new SettingsFormUseCase();
    }

    public createSettingsTableUseCase(): ISettingsTableUseCase {
        return new SettingsTableUseCase();
    }

    public createSettingsUserUseCase(): ISettingsUserUseCase {
        return new SettingsUserUseCase();
    }

    //

    public createReportUseCase(): IReportUseCase {
        return new ReportUseCase();
    }

    //

    public createTrackingUseCase(): ITrackingUseCase {
        return new TrackingUseCase();
    }

    //

    public createAdminAuthUseCase(): IAdminAuthUseCase {
        return new AdminAuthUseCase();
    }

    public createAdminCompanyUseCase(): IAdminCompanyUseCase {
        return new AdminCompanyUseCase();
    }

    public createAdminAnalyticsUseCase(): IAdminAnalyticsUseCase {
        return new AdminAnalyticsUseCase();
    }

    public createAdminUserUseCase(): IAdminUserUseCase {
        throw new Error("Method not implemented.");
    }
}
