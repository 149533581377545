import {
    IGoodUseCase,
    IGood,
    ISelectedData,
    IGoodCreateDto,
    IGoodUpdateDto,
    IGoodMoveDto,
    IGoodDeleteManyDto,
    IGoodImportDto,
    IGoodMoveManyDto,
    IGoodHistoryItem,
    IReadQuery,
} from "@lib";
import { DataLoadException } from "@/core/exceptions";
import { Api } from "../axios";
import { QueryConverter } from "@/utils/plugins/query-converter";

export class GoodUseCase implements IGoodUseCase {
    public async select(companyId: string, query?: IReadQuery): Promise<ISelectedData<IGood>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGood>>(`/companies/${companyId}/goods`, { params });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectForStore(companyId: string, storeId: string, query?: IReadQuery): Promise<ISelectedData<IGood>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGood>>(`/companies/${companyId}/goods/for/${storeId}`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async selectForAvailableStores(companyId: string, query?: IReadQuery): Promise<ISelectedData<IGood>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGood>>(`/companies/${companyId}/goods/for/available`, {
                params,
            });
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async get(companyId: string, id: string): Promise<IGood> {
        try {
            const response = await Api.get<IGood>(`/companies/${companyId}/goods/${id}`);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async getHistory(
        companyId: string,
        id: string,
        query?: IReadQuery,
    ): Promise<ISelectedData<IGoodHistoryItem>> {
        try {
            const params = QueryConverter.objectToQuery(query);
            const response = await Api.get<ISelectedData<IGoodHistoryItem>>(
                `/companies/${companyId}/goods/${id}/history`,
                { params },
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async create(companyId: string, storeId: string, dto: IGoodCreateDto): Promise<IGood> {
        try {
            const body = { store: storeId, good: dto };
            const response = await Api.post<IGood>(`/companies/${companyId}/goods`, body);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async update(companyId: string, id: string, dto: IGoodUpdateDto): Promise<IGood> {
        try {
            const response = await Api.put<IGood>(`/companies/${companyId}/goods/${id}`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async move(companyId: string, id: string, dto: IGoodMoveDto): Promise<IGood> {
        try {
            const response = await Api.post<IGood>(`/companies/${companyId}/goods/${id}/move`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async moveMany(companyId: string, dto: IGoodMoveManyDto): Promise<IGood[]> {
        try {
            const response = await Api.post<IGood[]>(`/companies/${companyId}/goods/actions/move`, dto);
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async delete(companyId: string, id: string): Promise<void> {
        try {
            await Api.delete<void>(`/companies/${companyId}/goods/${id}`);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async deleteMany(companyId: string, dto: IGoodDeleteManyDto): Promise<void> {
        try {
            await Api.post<void>(`/companies/${companyId}/goods/actions/delete`, dto);
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async import(companyId: string, storeId: string, file: File, dto: IGoodImportDto): Promise<IGood[]> {
        try {
            const data = new FormData();
            data.append("file", file);
            for (const key in dto) {
                data.append(key, (dto as any)[key]);
            }

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const response = await Api.post<IGood[]>(
                `/companies/${companyId}/goods/actions/import/${storeId}`,
                data,
                config,
            );
            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }

    public async export(companyId: string, query?: IReadQuery): Promise<string> {
        try {
            // https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios

            const params = QueryConverter.objectToQuery(query);
            const response = await Api.post<string>(`/companies/${companyId}/goods/actions/export`, undefined, {
                params,
                responseType: "blob",
            });

            return response.data;
        } catch (e: any) {
            throw new DataLoadException(e);
        }
    }
}
